import PieChart from '@rio-cloud/rio-uikit/lib/es/PieChart';
import React from 'react';
import { LoadingPanel } from '../../../../components/LoadingPanel';
import ChartTooltip from '@rio-cloud/rio-uikit/lib/es/ChartTooltip';
import { useCustomIntl } from '../../../../i18n/i18n';
import { NoDataPanelBody } from '../../../../components/NoDataPanelBody';
import { SERVICE_TO_TRANSLATION_KEY } from '../../../../i18n/enumMappings';
import './charts.css';
import { ServiceType } from '../../../../codegen/vehicles';
import { truncate } from 'lodash';
import { getCurrencyDisplay } from '../../../../components/MonetaryValue';
import { Legend } from 'recharts';

type OperationalCostPanelBodyProps = {
  isLoading: boolean;
  chartData: {
    service: ServiceType;
    color: string;
    amount: number;
  }[];
  currency: string;
  totalAmount: number;
};

export const OperationalCostPanelBody: React.FC<
  OperationalCostPanelBodyProps
> = ({ isLoading, chartData, currency, totalAmount }) => {
  const intl = useCustomIntl();

  const prepareServiceTypeLabel = (service: ServiceType) => {
    return truncate(
      intl.formatMessage({
        id: SERVICE_TO_TRANSLATION_KEY[service],
      }),
      {
        length: 18,
        omission: '...',
      },
    );
  };

  return (
    <LoadingPanel loading={isLoading}>
      <div data-testid={'dashboard-operational-costs-panel-content'}>
        {totalAmount <= 0 ? (
          <NoDataPanelBody />
        ) : (
          <div
            style={{ height: '140px' }}
            className="padding-right-20"
          >
            <PieChart
              data={chartData.map((c) => {
                return {
                  ...c,
                  label: prepareServiceTypeLabel(c.service),
                };
              })}
              dataKey={'amount'}
              nameKey={'label'}
              paddingAngle={1}
              innerRadius={'50%'}
              outerRadius={'80%'}
              labels={false}
              pieOptions={{
                cx: 95,
                cy: 65,
              }}
              legend={
                <Legend
                  layout={'vertical'}
                  align="right"
                  verticalAlign={'middle'}
                />
              }
              tooltip={
                <ChartTooltip
                  formatter={(val: number) =>
                    intl.formatNumber(val, {
                      style: 'currency',
                      currency,
                      currencyDisplay: getCurrencyDisplay(currency),
                    })
                  }
                />
              }
            />
          </div>
        )}
      </div>
    </LoadingPanel>
  );
};
