import { User, UserManager } from 'oidc-client-ts';
import { useEffect, useState } from 'react';

export const useOidcUser = (manager: UserManager): User | undefined => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    manager.getUser().then((u) => {
      setUser(u ?? undefined);
    });
  }, [manager]);

  return user;
};
