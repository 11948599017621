import { ImageLoader } from '../../../ImageLoader';
import { useLocale } from '../../../../i18n/locale';
import { getImageAssetWithLocale, imageKeys } from './imageAssets';
import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';
import { ChapterSubHeadline } from '../chapterComponents/ChapterSubHeadline';

const WelcomeChapter: React.FC = () => {
  const { locale } = useLocale();

  const welcomeImage = getImageAssetWithLocale(imageKeys.welcome, locale);

  return (
    <div className={'row'}>
      <ChapterHeadline messageId={'userManual.welcome.headline'} />
      <ImageLoader
        src={welcomeImage}
        className="col-xs-12 margin-bottom-25"
      />
      <ChapterSubHeadline messageId={'userManual.welcome.subHeadline'} />
      <ChapterText messageId={'userManual.welcome.text'} />
    </div>
  );
};

export { WelcomeChapter };
