import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import React from 'react';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { RioSupportInformation } from './RioSupportInformation';
import {
  closeSupportDialog,
  isSupportDialogOpen,
} from '../../../state/dialogs/useSupportDialog';

export const SupportDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isSupportDialogOpen);
  const handleClose = () => {
    dispatch(closeSupportDialog());
  };

  // TODO: workaround for a bug in RIO UIKit dialog (attribute show seems not to change the classname)
  return (
    isOpen && (
      <Dialog
        data-testid={'support-dialog'}
        show={isOpen}
        title={<CustomFormattedMessage id={'common.information.moduleName'} />}
        body={<RioSupportInformation />}
        bsSize={Dialog.SIZE_SM}
        onClose={handleClose}
        showCloseButton
      />
    )
  );
};
