import { useLocation, useNavigate } from 'react-router-dom';
import { routeStorage } from '../routes/routeStorage';
import { useEffect } from 'react';
import { notifyError } from '../components/showNotification';
import { ONBOARDING_ROUTE } from '../routes/routes';

export const REDIRECT_SOURCE_EDENRED = 'edenred';

export const RedirectPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const redirectSource = queryParams.get('source');

  useEffect(() => {
    const initialRoute = routeStorage.getRoute();
    let route = initialRoute ?? '/';

    if (redirectSource === REDIRECT_SOURCE_EDENRED) {
      const storedVehicleId = routeStorage.getLastVehicleId();
      const externalLoginError = routeStorage.getExternalLoginError();
      if (storedVehicleId) {
        route =
          `${ONBOARDING_ROUTE}?vehicleId=${storedVehicleId}` +
          '&editDialog=SERVICE_CARDS&addNewCardDialog=UTA_PAYMENT';
        if (externalLoginError) {
          route = route.concat(`&externalLoginError=${externalLoginError}`);
        }
        routeStorage.clearLastVehicleId();
        routeStorage.clearExternalLoginError();
      }
    }
    if (redirectSource && redirectSource !== REDIRECT_SOURCE_EDENRED) {
      notifyError(
        'base.body.notificationBanner.oauthRedirectFailureTitle',
        'base.body.notificationBanner.oauthRedirectFailureMessage.unknownSource',
      );
    }
    navigate(route, { replace: true });
  }, [redirectSource]);

  return <></>;
};
