import React from 'react';
import manCardIcon from '../../assets/images/fuelCardTypes/man-card-logo.svg';
import utaCardIcon from '../../assets/images/fuelCardTypes/uta_card_logo.svg';
import { PaymentPartnerType } from '../../codegen/vehicles';
import { TranslationsKeys, useCustomIntl } from '../../i18n/i18n';

type ServiceCardTypeIconProps = {
  type: PaymentPartnerType;
  className: string;
};

const paymentPartnerToIcon: { [key in PaymentPartnerType]: string } = {
  UTA_PAYMENT: utaCardIcon,
  MOCK_FUELING_PAYMENT: manCardIcon,
};

const paymentPartnerToAltText: {
  [key in PaymentPartnerType]: TranslationsKeys;
} = {
  UTA_PAYMENT: 'common.fuelCardType.utaFuelCard',
  MOCK_FUELING_PAYMENT: 'common.fuelCardType.manFuelCard',
};

export const ServiceCardTypeIcon: React.FC<ServiceCardTypeIconProps> = ({
  type,
  className,
}) => {
  const intl = useCustomIntl();

  return (
    <img
      alt={intl.formatMessage({ id: paymentPartnerToAltText[type] })}
      src={paymentPartnerToIcon[type]}
      className={className}
    />
  );
};
