import { PaymentPartnerType } from '../../codegen/transactions';
import { PAYMENT_PARTNER_TO_TRANSLATION_KEY } from '../../i18n/enumMappings';
import { CustomFormattedMessage } from '../../i18n/i18n';
import { PaymentPartnerIcon } from '../icons/PaymentPartnerIcon';

type PaymentPartnerRepresentationProps = {
  paymentPartner: PaymentPartnerType;
};

export const PaymentPartnerRepresentation = ({
  paymentPartner,
}: PaymentPartnerRepresentationProps) => {
  return (
    <div className="width-120">
      <PaymentPartnerIcon
        paymentPartner={paymentPartner}
        className="height-20 margin-right-10 margin-bottom-0"
      />
      <CustomFormattedMessage
        id={PAYMENT_PARTNER_TO_TRANSLATION_KEY[paymentPartner]}
      />
    </div>
  );
};
