import React from 'react';
import { TransactionTable } from './components/table/TransactionsTable';
import { useTransactionUpdateNotification } from '../../components/useTransactionUpdateNotification';
import { TransactionsTableToolbar } from './components/table/TransactionsTableToolbar';

export const TransactionPage: React.FC = () => {
  useTransactionUpdateNotification();

  return (
    <>
      <TransactionsTableToolbar />
      <TransactionTable />
    </>
  );
};
