import { ImageLoader } from '../../../ImageLoader';
import { useLocale } from '../../../../i18n/locale';
import { getImageAssetWithLocale, imageKeys } from './imageAssets';
import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';

export const VehicleOverviewChapter: React.FC = () => {
  const { locale } = useLocale();

  const vehicleOverview1 = getImageAssetWithLocale(
    imageKeys.vehicleOverview1,
    locale,
  );
  const vehicleOverview2 = getImageAssetWithLocale(
    imageKeys.vehicleOverview2,
    locale,
  );

  return (
    <div className={'row'}>
      <ChapterHeadline messageId={'userManual.vehicleOverview.headline'} />
      <ChapterText
        messageId={'userManual.vehicleOverview.cardManagement.text'}
      />
      <ImageLoader
        src={vehicleOverview1}
        className={'col-xs-12 margin-bottom-25'}
      />
      <ChapterText
        messageId={'userManual.vehicleOverview.vehicleOptions.text'}
      />
      <ImageLoader
        src={vehicleOverview2}
        className={'col-xs-12 margin-bottom-25'}
      />
      <ChapterText
        messageId={'userManual.vehicleOverview.cardOnboarding.text'}
      />
    </div>
  );
};
