import { ImageLoader } from '../../../ImageLoader';
import { useLocale } from '../../../../i18n/locale';
import { getImageAssetWithLocale, imageKeys } from './imageAssets';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';
import { ChapterSubHeadline } from '../chapterComponents/ChapterSubHeadline';
import { ChapterCarousel } from '../chapterComponents/ChapterCarousel';
import { ChapterBulletPoint } from '../chapterComponents/ChapterBulletPoint';
import { MobileAppInfo } from '../chapterComponents/MobileAppInfo';

export const MobileFuelingChapter: React.FC = () => {
  const { locale } = useLocale();

  const mobileFueling1 = getImageAssetWithLocale(
    imageKeys.mobileFueling1,
    locale,
  );
  const mobileFueling2 = getImageAssetWithLocale(
    imageKeys.mobileFueling2,
    locale,
  );
  const mobileFueling3 = getImageAssetWithLocale(
    imageKeys.mobileFueling3,
    locale,
  );
  const mobileFueling4 = getImageAssetWithLocale(
    imageKeys.mobileFueling4,
    locale,
  );

  const mobileFuelingDemo1 = getImageAssetWithLocale(
    imageKeys.mobileFuelingDemo1,
    locale,
  );
  const mobileFuelingDemo2 = getImageAssetWithLocale(
    imageKeys.mobileFuelingDemo2,
    locale,
  );
  const mobileFuelingDemo3 = getImageAssetWithLocale(
    imageKeys.mobileFuelingDemo3,
    locale,
  );
  const mobileFuelingDemo4 = getImageAssetWithLocale(
    imageKeys.mobileFuelingDemo4,
    locale,
  );
  const mobileFuelingDemo5 = getImageAssetWithLocale(
    imageKeys.mobileFuelingDemo5,
    locale,
  );
  const mobileFuelingDemo6 = getImageAssetWithLocale(
    imageKeys.mobileFuelingDemo6,
    locale,
  );

  return (
    <div className={'row'}>
      <ChapterHeadline
        messageId={'userManual.mobileFueling.activationUse.headline'}
      />
      <ChapterText
        messageId={'userManual.mobileFueling.activationUse.text'}
        marginBottom={15}
      />
      <ChapterSubHeadline
        messageId={'userManual.mobileFueling.driverApp.subHeadline'}
      />
      <ChapterText
        messageId={'userManual.mobileFueling.driverApp.text'}
        marginBottom={10}
      />
      <ul className={'col-xs-12 padding-left-25 margin-bottom-15'}>
        <ChapterBulletPoint
          messageId={'userManual.mobileFueling.driverApp.bulletPoint1'}
        />
        <ChapterBulletPoint
          messageId={'userManual.mobileFueling.driverApp.bulletPoint2'}
        />
        <ChapterBulletPoint
          messageId={'userManual.mobileFueling.driverApp.bulletPoint3'}
        />
      </ul>
      <div className={'col-xs-12'}>
        <div className={'col-xs-3 margin-bottom-15 '}>
          <ImageLoader src={mobileFueling1} />
        </div>
        <div className={'col-xs-3 margin-bottom-15 '}>
          <ImageLoader src={mobileFueling2} />
        </div>
        <div className={'col-xs-3 margin-bottom-15 '}>
          <ImageLoader src={mobileFueling3} />
        </div>
        <div className={'col-xs-3 margin-bottom-15 '}>
          <ImageLoader src={mobileFueling4} />
        </div>
      </div>
      <MobileAppInfo className={'padding-left-20 padding-right-20'} />

      <ChapterSubHeadline
        messageId={'userManual.mobileFueling.mmtApp.subHeadline'}
      />
      <ChapterText
        messageId={'userManual.mobileFueling.mmtApp.text'}
        marginBottom={15}
      />
      <ul className={'col-xs-12 padding-left-25 margin-bottom-25'}>
        <ChapterBulletPoint
          messageId={'userManual.mobileFueling.mmtApp.bulletPoint1'}
        />
        <ChapterBulletPoint
          messageId={'userManual.mobileFueling.mmtApp.bulletPoint2'}
        />
      </ul>

      <div className={'col-xs-12 text-size-12'}>
        <CustomFormattedMessage id={'userManual.mobileFueling.mmtApp.note'} />
      </div>
      <ul className={'col-xs-12 padding-left-25 margin-bottom-25 text-size-12'}>
        <ChapterBulletPoint
          messageId={'userManual.mobileFueling.mmtApp.note.bulletPoint1'}
        />
        <ChapterBulletPoint
          messageId={'userManual.mobileFueling.mmtApp.note.bulletPoint2'}
        />
      </ul>
      <ChapterCarousel
        images={[
          { src: mobileFuelingDemo1 },
          { src: mobileFuelingDemo2 },
          { src: mobileFuelingDemo3 },
          { src: mobileFuelingDemo4 },
          { src: mobileFuelingDemo5 },
          { src: mobileFuelingDemo6 },
        ]}
      />
    </div>
  );
};
