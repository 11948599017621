import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterSubHeadline } from '../chapterComponents/ChapterSubHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';
import { ChapterNote } from '../chapterComponents/ChapterNote';

export const RepairMaintenanceChapter: React.FC = () => {
  return (
    <div className={'row'}>
      <ChapterHeadline
        messageId={'userManual.repairMaintenance.activationUse.headline'}
      />
      <ChapterText
        messageId={'userManual.repairMaintenance.activationUse.text'}
      />
      <ChapterSubHeadline
        messageId={'userManual.repairMaintenance.paymentGuarantee.subHeadline'}
      />
      <ChapterText
        messageId={'userManual.repairMaintenance.paymentGuarantee.text'}
      />
      <ChapterNote
        messageId={'userManual.repairMaintenance.paymentGuarantee.note'}
      />
    </div>
  );
};
