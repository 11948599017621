import mapImage from '../../../../assets/images/bookingPage/fleet-monitor-link.svg';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import classNames from 'classnames';
import './fleetMonitorLink.css';
import { appConfiguration } from '../../../../configuration/appConfiguration';

type FleetMonitorLinkProps = {
  horizontal: boolean;
};

export const FleetMonitorLink = ({ horizontal }: FleetMonitorLinkProps) => {
  return (
    <div
      className={classNames(
        'panel',
        'panel-default',
        'panel-body',
        'padding-20',
        'height-100pct',
        'text-center',
        'display-flex',
        'flex-column',
        horizontal ? 'justify-content-between' : 'flex-start',
      )}
    >
      <img
        alt={''}
        src={mapImage}
        className={classNames(
          'margin-bottom-10',
          'fleet-monitor-link-image',
          horizontal ? 'height-50' : 'height-auto',
        )}
      />

      <div>
        <h4>
          <CustomFormattedMessage id={'bookings.fleetMonitorLink.headline'} />
        </h4>
        <p>
          <CustomFormattedMessage id={'bookings.fleetMonitorLink.body'} />
        </p>
      </div>
      <a
        href={appConfiguration.fleetMonitorWidgetLink}
        target="_blank"
        className="btn btn-primary btn-block text-decoration-none margin-top-15"
        rel="noreferrer"
        data-testId={'bookings-fleet-monitor-link'}
      >
        <CustomFormattedMessage id={'bookings.fleetMonitorLink.button'} />
      </a>
    </div>
  );
};
