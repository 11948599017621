import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../state/hooks';
import { useEffect } from 'react';
import { openUserManualDialog } from '../state/dialogs/useUserManualDialog';

export const PARAMETER_USER_MANUAL_CHAPTER = 'userManualChapter';

export const useCheckUserManualChapter = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const userManualChapter = queryParams.get(PARAMETER_USER_MANUAL_CHAPTER);
    if (userManualChapter) {
      dispatch(openUserManualDialog(userManualChapter));
    }
  }, []);
};
