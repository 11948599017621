import { ServiceType } from '../../../../codegen/transactions';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { SERVICE_TO_TRANSLATION_KEY } from '../../../../i18n/enumMappings';
import { serviceTypeColors } from '../../../../components/colors/ServiceTypeColors';

type Props = { service: ServiceType; count: number };

export const TransactionCountForService = ({ service, count }: Props) => {
  const width = 110;

  if (count === 0) {
    return <></>;
  }

  const cssTextColorClass: string =
    serviceTypeColors[service].textColorCssClass;

  return (
    <div
      data-testid={`dashboard-transaction-count-for-service-${service.toLowerCase()}`}
      className={`height-90 display-flex flex-column align-items-center ${cssTextColorClass}`}
      style={{ width }}
    >
      <div
        data-testid={'count'}
        className="text-medium"
        style={{ fontSize: 30 }}
      >
        {count}
      </div>
      <div
        data-testid={'label'}
        style={{ textAlign: 'center' }}
      >
        <CustomFormattedMessage id={SERVICE_TO_TRANSLATION_KEY[service]} />
      </div>
    </div>
  );
};
