import React, { useState } from 'react';
import { CustomFormattedDate, useCustomIntl } from '../../../../i18n/i18n';
import { MonetaryValue } from '../../../../components/MonetaryValue';
import { TransactionStateLabel } from '../TransactionStateLabel';
import {
  getTransactionPage,
  setPage,
  TRANSACTION_TABLE_PAGE_SIZE,
  useTableFilteredTransactions,
} from '../../../../state/transactions/transactionFilterSlice';
import {
  openSidebar,
  setSidebarTransaction,
} from '../../state/transactionsUiSlice';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import { ServiceRepresentation } from '../../../../components/dataRepresentation/ServiceRepresentation';
import { TableColumn } from '../../../../components/table/TableRow';
import { RowData, Table } from '../../../../components/table/Table';
import { Pagination } from '../../../../components/table/Pagination';
import { useVehicles } from '../../../../state/vehicles/useVehicles';
import { TransactionWithVehicle } from '../../../../models';
import { mapTransactionsToRows, TransactionRow } from './mapTransactionsToRows';
import { VehicleRepresentation } from '../../../../components/dataRepresentation/VehicleRepresentation';
import { NoDataPlaceholder } from '../../../../components/NoDataPlaceholder';
import { PaymentPartnerRepresentation } from '../../../../components/dataRepresentation/PaymentPartnerRepresentation';
import { MaskedCardNumber } from '../../../../components/MaskedCardNumber';

export const TransactionTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();
  const { isLoading: isVehiclesLoading, error: errorVehicles } = useVehicles();
  const {
    filteredTransactions,
    totalTransactions,
    isLoading: isTransactionsLoading,
    error: errorTransactions,
  } = useTableFilteredTransactions();
  const isLoading = isVehiclesLoading || isTransactionsLoading;
  const error = errorVehicles || errorTransactions;

  const [highlightedRow, setHighlightedRow] = useState<string | undefined>();

  const { vehicles } = useVehicles();

  const page = useAppSelector(getTransactionPage);
  const transactionsVehicleMap: Map<string, TransactionWithVehicle> = new Map();

  const handleTransactionClicked = (id: string) => {
    dispatch(setSidebarTransaction(transactionsVehicleMap.get(id)!));
    dispatch(openSidebar());
  };

  const handlePageChanged = (pageNumber: number) => {
    dispatch(setPage(pageNumber));
  };

  const columns: TableColumn<TransactionRow>[] = [
    {
      id: 'vehicle',
      label: intl.formatMessage({
        id: 'transactions.transactionsTable.header.vehicle',
      }),
      renderer: (transaction: TransactionRow) => (
        <VehicleRepresentation
          vehicleType={transaction.vehicle?.type}
          vehicleName={transaction.vehicle?.name}
        />
      ),
    },
    {
      id: 'service',
      label: intl.formatMessage({
        id: 'transactions.transactionsTable.header.service',
      }),
      renderer: (transaction: TransactionRow) => (
        <ServiceRepresentation serviceType={transaction.service} />
      ),
    },
    {
      id: 'transactionDate',
      label: intl.formatMessage({
        id: 'transactions.transactionsTable.header.transactionDate',
      }),
      renderer: (transaction: TransactionRow) => (
        <CustomFormattedDate value={transaction.transactionDate} />
      ),
    },
    {
      id: 'billingPartner',
      label: intl.formatMessage({
        id: 'transactions.transactionsTable.header.paymentPartner',
      }),
      renderer: (transaction: TransactionRow) => (
        <PaymentPartnerRepresentation
          paymentPartner={transaction.billingPartner}
        />
      ),
    },
    {
      id: 'serviceCardLastFourDigits',
      label: intl.formatMessage({
        id: 'common.card.type',
      }),
      renderer: (transaction: TransactionRow) => (
        <MaskedCardNumber
          lastFourDigits={transaction.serviceCardLastFourDigits}
        />
      ),
    },
    {
      id: 'totalPrice',
      label: intl.formatMessage({
        id: 'common.transaction.totalPriceNet',
      }),
      renderer: (transaction: TransactionRow) => (
        <span className={'text-medium'}>
          {!!transaction.totalPrice && (
            <MonetaryValue
              amount={parseFloat(transaction.totalPrice.amountNet)}
              currency={transaction.totalPrice.currency}
            />
          )}
        </span>
      ),
    },
    {
      id: 'totalPriceConverted',
      label: intl.formatMessage({
        id: 'common.transaction.totalPriceNetConverted',
      }),
      renderer: (transaction: TransactionRow) => (
        <span className={'text-medium'}>
          {!!transaction.totalPriceConverted && (
            <MonetaryValue
              amount={parseFloat(transaction.totalPriceConverted.amountNet)}
              currency={transaction.totalPriceConverted.currency}
            />
          )}
        </span>
      ),
    },
    {
      id: 'status',
      label: intl.formatMessage({
        id: 'transactions.transactionsTable.header.status',
      }),
      renderer: (transaction: TransactionRow) => (
        <TransactionStateLabel state={transaction.status} />
      ),
    },
  ];

  const rowData: RowData<TransactionRow>[] = mapTransactionsToRows(
    filteredTransactions,
    vehicles,
    transactionsVehicleMap,
  );

  const numberOfPages = Math.ceil(
    totalTransactions / TRANSACTION_TABLE_PAGE_SIZE,
  );
  const calculateBottomOffset = () => (numberOfPages <= 1 ? 30 : 70);

  return (
    <>
      <div
        className="table-responsive overflow-y-auto"
        data-testid="transactions-body-transactions-table"
      >
        {error ? (
          <NoDataPlaceholder />
        ) : (
          <Table
            columns={columns}
            rowData={rowData}
            highlightedRow={highlightedRow}
            onRowClicked={(key) => {
              setHighlightedRow(key);
              handleTransactionClicked(key);
            }}
            isLoading={isLoading}
            bottomOffset={calculateBottomOffset()}
          />
        )}
      </div>
      <Pagination
        currentPage={page}
        numberOfPages={numberOfPages}
        onPageChanged={handlePageChanged}
      />
    </>
  );
};
