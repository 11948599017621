import { CustomFormattedMessage } from '../../../../../i18n/i18n';
import map from 'lodash/map';
import { ServiceActivation } from '../../../../../codegen/vehicles';
import { ServiceRepresentation } from '../../../../../components/dataRepresentation/ServiceRepresentation';

type RemoveConfirmationDialogBodyProps = {
  serviceActivations: ServiceActivation[];
};

export const RemoveConfirmationDialogBody = ({
  serviceActivations,
}: RemoveConfirmationDialogBodyProps) => {
  return (
    <>
      <div className="text-size-16 text-medium margin-top-20 margin-bottom-20">
        <CustomFormattedMessage
          id={'onboarding.dialog.removeCardConfirmation.message'}
        />
      </div>
      <ul>
        {map(serviceActivations, (serviceActivation: ServiceActivation) => {
          return (
            <li className={'margin-top-10 margin-bottom-10'}>
              <ServiceRepresentation
                serviceType={serviceActivation.serviceType}
                representationSize="large"
              />
            </li>
          );
        })}
      </ul>
      <div className="text-size-16 text-medium margin-top-20 margin-bottom-20">
        <CustomFormattedMessage
          id={'onboarding.dialog.removeCardConfirmation.question'}
        />
      </div>
    </>
  );
};
