import { useLocation, useNavigate } from 'react-router-dom';
import { useEntityUpdateNotification } from './useEntityUpdateNotification';
import { ONBOARDING_ROUTE } from '../routes/routes';
import { useAppDispatch } from '../state/hooks';
import {
  DialogMode,
  openConfigurationDialog,
  setSelectedVehicleId,
} from '../pages/onboarding/state/onboardingUiSlice';
import { PaymentMethodState } from '../codegen/vehicles';
import { usePaymentMethodUpdateState } from '../state/vehicles/paymentMethodUpdateSlice';
import { CustomFormattedMessage } from '../i18n/i18n';

export const usePaymentMethodUpdateNotification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    paymentMethodUpdate: { paymentMethodState, vehicleId } = {},
    setPaymentMethodUpdate,
  } = usePaymentMethodUpdateState();

  const navigateToServiceCardDialog = () => {
    if (location.pathname !== ONBOARDING_ROUTE) {
      navigate(ONBOARDING_ROUTE);
    }
    if (vehicleId) {
      dispatch(setSelectedVehicleId(vehicleId));
      dispatch(openConfigurationDialog(DialogMode.SERVICE_CARDS));
    }
  };

  const notificationMessage = () => {
    const messageId =
      paymentMethodState === PaymentMethodState.Active
        ? 'common.paymentMethod.status.active.notification'
        : 'common.paymentMethod.status.failure.notification';
    return (
      <span
        className="text-decoration-underline"
        onClick={navigateToServiceCardDialog}
      >
        <CustomFormattedMessage id={messageId} />
      </span>
    );
  };

  useEntityUpdateNotification({
    type:
      paymentMethodState === PaymentMethodState.Active ? 'success' : 'error',
    showNotification:
      !!paymentMethodState &&
      !!vehicleId &&
      paymentMethodState !== PaymentMethodState.OnboardingPending,
    message: notificationMessage(),
    onClose: () => setPaymentMethodUpdate(undefined),
  });
};
