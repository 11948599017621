import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enhancedApi, PaymentMethodState } from '../../codegen/vehicles';
import { useAppDispatch, useAppSelector } from '../hooks';

type PaymentMethodUpdate = {
  vehicleId: string;
  paymentMethodId: string;
  paymentMethodState: PaymentMethodState;
};

export type PaymentMethodUpdateState = {
  update?: PaymentMethodUpdate;
};

const initialState: PaymentMethodUpdateState = {
  update: undefined,
};

export const paymentMethodUpdateSlice = createSlice({
  name: 'paymentMethodUpdate',
  initialState,
  reducers: {
    setPaymentMethodUpdate: (
      state,
      action: PayloadAction<PaymentMethodUpdate>,
    ) => {
      state.update = action.payload;
    },
  },
});

export const usePaymentMethodUpdateState = () => {
  const dispatch = useAppDispatch();

  const paymentMethodUpdate = useAppSelector(
    (state) => state.paymentMethodUpdate.update,
  );

  const setPaymentMethodUpdate = (update?: PaymentMethodUpdate) => {
    dispatch(
      enhancedApi.util.updateQueryData(
        'getVehicles',
        undefined,
        (draftedVehicles) => {
          const vehicleToUpdate = draftedVehicles?.items.find(
            (v) => v.id === update?.vehicleId,
          );
          const methodToUpdate = vehicleToUpdate?.paymentMethods.find(
            (pm) => pm.id === update?.paymentMethodId,
          );
          if (methodToUpdate) {
            methodToUpdate.state = update!.paymentMethodState;
          }
        },
      ),
    );
    dispatch(paymentMethodUpdateSlice.actions.setPaymentMethodUpdate(update!));
  };

  return {
    paymentMethodUpdate,
    setPaymentMethodUpdate,
  };
};
