import { useTransactionUpdateState } from '../state/transactions/transactionUpdateSlice';
import { useTableFilteredTransactions } from '../state/transactions/transactionFilterSlice';
import { useEntityUpdateNotification } from './useEntityUpdateNotification';
import { CustomFormattedMessage } from '../i18n/i18n';

export const useTransactionUpdateNotification = () => {
  const {
    hasTransactionUpdate,
    needToNotify,
    setNeedToNotify,
    reset: resetTransactionUpdateState,
  } = useTransactionUpdateState();
  const { resetApiState } = useTableFilteredTransactions();

  const handleRefreshTransactions = () => {
    resetApiState();
    resetTransactionUpdateState();
  };

  const notificationMessage = (
    <CustomFormattedMessage
      id="transactions.notification.hasUpdate"
      values={{
        link: (parts: string[]) => (
          <span
            className="text-decoration-underline"
            onClick={handleRefreshTransactions}
          >
            {parts}
          </span>
        ),
      }}
    />
  );

  useEntityUpdateNotification({
    showNotification: hasTransactionUpdate && needToNotify,
    message: notificationMessage,
    onClose: () => setNeedToNotify(false),
  });
};
