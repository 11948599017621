import { ImageLoader } from '../../../ImageLoader';
import { useLocale } from '../../../../i18n/locale';
import { getImageAssetWithLocale, imageKeys } from './imageAssets';
import { paymentMethodStateTextColors } from '../../../colors/paymentMethodStateTextColors';
import { PAYMENT_METHOD_STATE_TO_TRANSLATION_KEY } from '../../../../i18n/enumMappings';
import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';
import { ChapterCarousel } from '../chapterComponents/ChapterCarousel';

export const AddingServiceCardsChapter: React.FC = () => {
  const { locale } = useLocale();

  const addServiceCard1 = getImageAssetWithLocale(
    imageKeys.addingServiceCard1,
    locale,
  );
  const addServiceCard2 = getImageAssetWithLocale(
    imageKeys.addingServiceCard2,
    locale,
  );
  const addServiceCard3 = getImageAssetWithLocale(
    imageKeys.addingServiceCard3,
    locale,
  );
  const addServiceCard4 = getImageAssetWithLocale(
    imageKeys.addingServiceCard4,
    locale,
  );
  const addServiceCard5 = getImageAssetWithLocale(
    imageKeys.addingServiceCard5,
    locale,
  );
  const addServiceCard6 = getImageAssetWithLocale(
    imageKeys.addingServiceCard6,
    locale,
  );

  return (
    <div className={'row'}>
      <ChapterHeadline messageId={'userManual.addingServiceCards.headline'} />
      <ChapterText messageId={'userManual.addingServiceCards.text1'} />
      <ImageLoader
        src={addServiceCard1}
        className="col-xs-12 margin-bottom-25"
      />
      <ChapterText messageId={'userManual.addingServiceCards.text2'} />
      <ChapterCarousel
        images={[
          { src: addServiceCard1 },
          { src: addServiceCard2 },
          { src: addServiceCard3 },
        ]}
      />
      <ChapterText messageId={'userManual.addingServiceCards.status.text'} />
      <div className={'col-xs-12 row-xs-12 display-flex align-items-center'}>
        <ImageLoader
          src={addServiceCard4}
          className="border-style-solid border-width-1 border-color-light col-xs-5 row-xs-12"
        />
        <div
          className={
            'col-xs-7 display-flex flex-column justify-content-between min-height-300'
          }
        >
          <div>
            <ChapterText
              colSize={4}
              className={`text-center ${paymentMethodStateTextColors.ACTIVE}`}
              messageId={PAYMENT_METHOD_STATE_TO_TRANSLATION_KEY.ACTIVE}
            />
            <ChapterText
              colSize={8}
              messageId={
                'userManual.addingServiceCards.status.active.description'
              }
            />
          </div>
          <div>
            <ChapterText
              colSize={4}
              className={`text-center ${paymentMethodStateTextColors.ONBOARDING_PENDING}`}
              messageId={
                PAYMENT_METHOD_STATE_TO_TRANSLATION_KEY.ONBOARDING_PENDING
              }
            />
            <ChapterText
              colSize={8}
              messageId={
                'userManual.addingServiceCards.status.pending.description'
              }
            />
          </div>
          <div>
            <ChapterText
              colSize={4}
              className={`text-center ${paymentMethodStateTextColors.ONBOARDING_FAILURE}`}
              messageId={
                PAYMENT_METHOD_STATE_TO_TRANSLATION_KEY.ONBOARDING_FAILURE
              }
            />
            <ChapterText
              colSize={8}
              messageId={
                'userManual.addingServiceCards.status.activationFailed.description'
              }
            />
          </div>
        </div>
      </div>
      <ImageLoader
        src={addServiceCard5}
        className={'col-xs-12 margin-bottom-15'}
      />
      <ChapterText
        messageId={'userManual.addingServiceCards.status.activationFailed.text'}
      />
      <ImageLoader
        src={addServiceCard6}
        className={'col-xs-12 margin-bottom-15'}
      />
      <ChapterText
        messageId={'userManual.addingServiceCards.removeServiceCard.text'}
      />
    </div>
  );
};
