import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlBaseQuery } from '../../api/graphql/baseQuery';
import { listVehicleGroups } from '../../api/graphql/queries';
import { User } from '../../login/loginSlice';
import { VehicleGroup } from '../../models';

type VehicleGroupsResponse = {
  items: VehicleGroup[];
};

const vehicleGroupsApiSlice = createApi({
  reducerPath: 'vehicleGroupsApi',
  baseQuery: graphqlBaseQuery,
  endpoints: (builder) => ({
    getVehicleGroups: builder.query<VehicleGroup[], { user: User }>({
      query: ({ user }) => ({
        request: listVehicleGroups,
        user,
      }),
      transformResponse: (response: {
        listVehicleGroups: VehicleGroupsResponse;
      }): VehicleGroup[] => response.listVehicleGroups?.items,
    }),
  }),
});

export const {
  reducer: vehicleGroupsReducer,
  reducerPath: vehicleGroupsReducerPath,
  middleware: vehicleGroupsMiddleware,
  useGetVehicleGroupsQuery,
} = vehicleGroupsApiSlice;
