import React from 'react';
import { CustomFormattedMessage, TranslationsKeys } from '../i18n/i18n';
import { HighlightPanel } from './HighlightPanel';
import { useLocale } from '../i18n/locale';

type ErrorMessagePanelProps = {
  message?: TranslationsKeys;
};

const DEFAULT_ERROR_MESSAGE: TranslationsKeys = 'common.defaultErrorMessage';

export const ErrorMessagePanel: React.FC<ErrorMessagePanelProps> = ({
  message,
}) => {
  const { locale } = useLocale();

  const renderRioSupportLink = (linkText: string[]) => (
    <a
      className="text-decoration-underline"
      href={`https://contact-form.rio.cloud/contact?opener=https%3A%2F%2Fuser-menu.iam.rio.cloud%2F&locale=${locale}`}
      target="_blank"
      rel="noreferrer"
    >
      {linkText}
    </a>
  );

  return (
    <div data-testid={'onboarding-dialog-error-message'}>
      <HighlightPanel type={'danger'}>
        <CustomFormattedMessage
          id={message || DEFAULT_ERROR_MESSAGE}
          values={{ link: renderRioSupportLink }}
        />
      </HighlightPanel>
    </div>
  );
};
