import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { PARAMETER_USER_MANUAL_CHAPTER } from '../../routes/useCheckUserManualChapter';
import { UserManualDialogChaptersKeys } from './chapters';

type DialogState = {
  open: boolean;
  chapter?: UserManualDialogChaptersKeys;
};

const initialState: DialogState = {
  open: false,
  chapter: undefined,
};

export const userManualDialogSlice = createSlice({
  name: 'userManualDialogUi',
  initialState,
  reducers: {
    openUserManualDialog: (
      state,
      action: PayloadAction<UserManualDialogChaptersKeys>,
    ) => {
      state.open = true;
      state.chapter = action.payload;
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set(PARAMETER_USER_MANUAL_CHAPTER, state.chapter);
      window.history.pushState(
        {},
        '',
        `${window.location.pathname}${currentUrlParams.size > 0 ? '?' : ''}${currentUrlParams.toString()}`,
      );
    },
    closeUserManualDialog: (state) => {
      state.open = false;

      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.delete(PARAMETER_USER_MANUAL_CHAPTER, state.chapter);
      state.chapter = undefined;

      window.history.pushState(
        {},
        '',
        `${window.location.pathname}${currentUrlParams.size > 0 ? '?' : ''}${currentUrlParams.toString()}`,
      );
    },
  },
});

export const { openUserManualDialog, closeUserManualDialog } =
  userManualDialogSlice.actions;

export const isUserManualDialogOpen = (state: RootState) =>
  state.userManualDialogState.open;

export const getOpenChapter = (
  state: RootState,
): UserManualDialogChaptersKeys | undefined =>
  state.userManualDialogState.chapter;
