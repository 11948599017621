import {
  useRemovePaymentMethodMutation,
  useUpdateServiceActivationsMutation,
} from '../../../../../codegen/vehicles';
import { useCustomIntl } from '../../../../../i18n/i18n';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks';
import { useVehicleDialogFormContext } from '../ServiceConfigurationForm';
import {
  closeRemoveConfirmationDialog,
  getRemoveConfirmationDialog,
  getSelectedVehicleId,
} from '../../../state/onboardingUiSlice';
import { RemoveConfirmationDialogBody } from './RemoveConfirmationDialogBody';
import { RemoveConfirmationDialogFooter } from './RemoveConfirmationDialogFooter';
import { useUpdatePaymentMethodsOfCachedVehicles } from '../../../../../state/vehicles/useVehicles';

export const RemoveConfirmationDialog = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();

  const selectedVehicleId = useAppSelector(getSelectedVehicleId);
  const {
    serviceActivations,
    paymentMethodId,
    open: showDialog,
  } = useAppSelector(getRemoveConfirmationDialog);

  const { removePaymentMethodFromCachedVehicles } =
    useUpdatePaymentMethodsOfCachedVehicles();

  const { getServiceActivations, removePaymentMethodFromServices } =
    useVehicleDialogFormContext();

  const [
    callUpdateServiceActivationsMutation,
    { isLoading: isUpdateServiceActivationLoading },
  ] = useUpdateServiceActivationsMutation();

  const [
    callRemovePaymentMethodMutation,
    { isLoading: isRemovePaymentMethodLoading },
  ] = useRemovePaymentMethodMutation();

  const closeDialog = () => {
    dispatch(closeRemoveConfirmationDialog());
  };

  const handleSubmit = async () => {
    const serviceActivationResult = await callUpdateServiceActivationsMutation({
      vehicleId: selectedVehicleId!,
      updateServiceActivationsRequest: {
        serviceActivations: Object.values(getServiceActivations()),
      },
    });

    if (!('data' in serviceActivationResult)) {
      return;
    }

    const removePaymentMethodResult = await callRemovePaymentMethodMutation({
      vehicleId: selectedVehicleId!,
      paymentMethodId,
    });

    if (!('data' in removePaymentMethodResult)) {
      return;
    }

    removePaymentMethodFromCachedVehicles(paymentMethodId);
    updateFormContext();
    closeDialog();
  };

  const updateFormContext = () => {
    const serviceTypes = serviceActivations.map((s) => s.serviceType);
    removePaymentMethodFromServices(serviceTypes, paymentMethodId);
  };

  if (!showDialog || !paymentMethodId) {
    return <></>;
  }

  return (
    <Dialog
      data-testid={'delete-payment-method-warning-dialog'}
      useOverflow
      bsSize={Dialog.SIZE_SM}
      show
      onClose={closeDialog}
      title={intl.formatMessage({
        id: 'onboarding.dialog.removeCardConfirmation.title',
      })}
      body={
        <RemoveConfirmationDialogBody serviceActivations={serviceActivations} />
      }
      footer={
        <RemoveConfirmationDialogFooter
          isLoading={
            isUpdateServiceActivationLoading || isRemovePaymentMethodLoading
          }
          onClose={closeDialog}
          onSubmit={handleSubmit}
        />
      }
    />
  );
};
