export const userManualDialogChapters = {
  welcome: 'welcome',
  vehicleOverview: 'vehicleOverview',
  addingServiceCards: 'addingServiceCards',
  activatingServices: 'activatingServices',
  dashboardFunctions: 'dashboardFunctions',
  displayTransactions: 'displayTransactions',
  bookingOverview: 'bookingOverview',
  mobileFueling: 'mobileFueling',
  parking: 'parking',
  repairMaintenance: 'repairMaintenance',
  washing: 'washing',
  charging: 'charging',
  otherOperatingCosts: 'otherOperatingCosts',
};

export type UserManualDialogChaptersKeys =
  (typeof userManualDialogChapters)[keyof typeof userManualDialogChapters];
