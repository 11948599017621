import welcomeImageDeDe from '../../../../assets/images/userManual/welcome_de-DE.png';
import vehicleOverview1DeDE from '../../../../assets/images/userManual/vehicle-overview-1_de-DE.png';
import vehicleOverview2DeDE from '../../../../assets/images/userManual/vehicle-overview-2_de-DE.png';
import addingServiceCard1DeDE from '../../../../assets/images/userManual/add-service-card-1_de-DE.png';
import addingServiceCard2DeDE from '../../../../assets/images/userManual/add-service-card-2_de-DE.png';
import addingServiceCard3DeDE from '../../../../assets/images/userManual/add-service-card-3_de-DE.png';
import addingServiceCard4DeDE from '../../../../assets/images/userManual/add-service-card-4_de-DE.png';
import addingServiceCard5DeDE from '../../../../assets/images/userManual/add-service-card-5_de-DE.png';
import addingServiceCard6DeDE from '../../../../assets/images/userManual/add-service-card-6_de-DE.png';
import activatingService1DeDE from '../../../../assets/images/userManual/activating-services-1_de-DE.png';
import activatingService2DeDE from '../../../../assets/images/userManual/activating-services-2_de-DE.png';
import dashboardOverview1DeDE from '../../../../assets/images/userManual/dashboard-overview-1_de-DE.png';
import dashboardOverviewCard1DeDE from '../../../../assets/images/userManual/dashboard-overview-card-1_de-DE.png';
import dashboardOverviewCard2DeDE from '../../../../assets/images/userManual/dashboard-overview-card-2_de-DE.png';
import dashboardOverviewCard3DeDE from '../../../../assets/images/userManual/dashboard-overview-card-3_de-DE.png';
import dashboardOverviewCard4DeDE from '../../../../assets/images/userManual/dashboard-overview-card-4_de-DE.png';
import dashboardOverviewCard5DeDE from '../../../../assets/images/userManual/dashboard-overview-card-5_de-DE.png';
import dashboardOverviewCard6DeDE from '../../../../assets/images/userManual/dashboard-overview-card-6_de-DE.png';
import dashboardOverviewCard7DeDE from '../../../../assets/images/userManual/dashboard-overview-card-7_de-DE.png';
import displayTransactions1DeDE from '../../../../assets/images/userManual/display-transactions-1_de-DE.png';
import displayTransactions2DeDE from '../../../../assets/images/userManual/display-transactions-2_de-DE.png';
import displayTransactions3DeDE from '../../../../assets/images/userManual/display-transactions-3_de-DE.png';
import bookingOverview1DeDE from '../../../../assets/images/userManual/booking-overview-1_de-DE.png';
import bookingOverview2DeDE from '../../../../assets/images/userManual/booking-overview-2_de-DE.png';
import mobileFueling1DeDE from '../../../../assets/images/userManual/mobile-fueling-1_de-DE.png';
import mobileFueling2DeDE from '../../../../assets/images/userManual/mobile-fueling-2_de-DE.png';
import mobileFueling3DeDE from '../../../../assets/images/userManual/mobile-fueling-3_de-DE.png';
import mobileFueling4DeDE from '../../../../assets/images/userManual/mobile-fueling-4_de-DE.png';
import mobileFuelingDemo1DeDE from '../../../../assets/images/userManual/mobile-fueling-demo-1_de-DE.png';
import mobileFuelingDemo2DeDE from '../../../../assets/images/userManual/mobile-fueling-demo-2_de-DE.png';
import mobileFuelingDemo3DeDE from '../../../../assets/images/userManual/mobile-fueling-demo-3_de-DE.png';
import mobileFuelingDemo4DeDE from '../../../../assets/images/userManual/mobile-fueling-demo-4_de-DE.png';
import mobileFuelingDemo5DeDE from '../../../../assets/images/userManual/mobile-fueling-demo-5_de-DE.png';
import mobileFuelingDemo6DeDE from '../../../../assets/images/userManual/mobile-fueling-demo-6_de-DE.png';
import parking1DeDE from '../../../../assets/images/userManual/parking-1_de-DE.png';
import parking2DeDE from '../../../../assets/images/userManual/parking-2_de-DE.png';
import parking3DeDE from '../../../../assets/images/userManual/parking-3_de-DE.png';
import parking4DeDE from '../../../../assets/images/userManual/parking-4_de-DE.png';
import parkingApp1DeDE from '../../../../assets/images/userManual/parking-app-1_de-DE.png';
import parkingApp2DeDE from '../../../../assets/images/userManual/parking-app-2_de-DE.png';
import parkingApp3DeDE from '../../../../assets/images/userManual/parking-app-3_de-DE.png';
import parkingApp4DeDE from '../../../../assets/images/userManual/parking-app-4_de-DE.png';

import welcomeImageEnGb from '../../../../assets/images/userManual/welcome_en-GB.png';
import vehicleOverview1EnGb from '../../../../assets/images/userManual/vehicle-overview-1_en-GB.png';
import vehicleOverview2EnGb from '../../../../assets/images/userManual/vehicle-overview-2_en-GB.png';
import addingServiceCard1EnGb from '../../../../assets/images/userManual/add-service-card-1_en-GB.png';
import addingServiceCard2EnGb from '../../../../assets/images/userManual/add-service-card-2_en-GB.png';
import addingServiceCard3EnGb from '../../../../assets/images/userManual/add-service-card-3_en-GB.png';
import addingServiceCard4EnGb from '../../../../assets/images/userManual/add-service-card-4_en-GB.png';
import addingServiceCard5EnGb from '../../../../assets/images/userManual/add-service-card-5_en-GB.png';
import addingServiceCard6EnGb from '../../../../assets/images/userManual/add-service-card-6_en-GB.png';
import activatingService1EnGb from '../../../../assets/images/userManual/activating-services-1_en-GB.png';
import activatingService2EnGb from '../../../../assets/images/userManual/activating-services-2_en-GB.png';
import dashboardOverview1EnGb from '../../../../assets/images/userManual/dashboard-overview-1_en-GB.png';
import dashboardOverviewCard1EnGb from '../../../../assets/images/userManual/dashboard-overview-card-1_en-GB.png';
import dashboardOverviewCard2EnGb from '../../../../assets/images/userManual/dashboard-overview-card-2_en-GB.png';
import dashboardOverviewCard3EnGb from '../../../../assets/images/userManual/dashboard-overview-card-3_en-GB.png';
import dashboardOverviewCard4EnGb from '../../../../assets/images/userManual/dashboard-overview-card-4_en-GB.png';
import dashboardOverviewCard5EnGb from '../../../../assets/images/userManual/dashboard-overview-card-5_en-GB.png';
import dashboardOverviewCard6EnGb from '../../../../assets/images/userManual/dashboard-overview-card-6_en-GB.png';
import dashboardOverviewCard7EnGb from '../../../../assets/images/userManual/dashboard-overview-card-7_en-GB.png';
import displayTransactions1EnGb from '../../../../assets/images/userManual/display-transactions-1_en-GB.png';
import displayTransactions2EnGb from '../../../../assets/images/userManual/display-transactions-2_en-GB.png';
import displayTransactions3EnGb from '../../../../assets/images/userManual/display-transactions-3_en-GB.png';
import bookingOverview1EnGb from '../../../../assets/images/userManual/booking-overview-1_en-GB.png';
import bookingOverview2EnGb from '../../../../assets/images/userManual/booking-overview-2_en-GB.png';
import mobileFueling1EnGb from '../../../../assets/images/userManual/mobile-fueling-1_en-GB.png';
import mobileFueling2EnGb from '../../../../assets/images/userManual/mobile-fueling-2_en-GB.png';
import mobileFueling3EnGb from '../../../../assets/images/userManual/mobile-fueling-3_en-GB.png';
import mobileFueling4EnGb from '../../../../assets/images/userManual/mobile-fueling-4_en-GB.png';
import manAppIcon from '../../../../assets/images/userManual/man-app-icon.png';
import manAppleStoreQr from '../../../../assets/images/userManual/man-apple-store-qr.png';
import manPlayStoreQr from '../../../../assets/images/userManual/man-play-store-qr.png';
import mobileFuelingDemo1EnGb from '../../../../assets/images/userManual/mobile-fueling-demo-1_en-GB.png';
import mobileFuelingDemo2EnGb from '../../../../assets/images/userManual/mobile-fueling-demo-2_en-GB.png';
import mobileFuelingDemo3EnGb from '../../../../assets/images/userManual/mobile-fueling-demo-3_en-GB.png';
import mobileFuelingDemo4EnGb from '../../../../assets/images/userManual/mobile-fueling-demo-4_en-GB.png';
import mobileFuelingDemo5EnGb from '../../../../assets/images/userManual/mobile-fueling-demo-5_en-GB.png';
import mobileFuelingDemo6EnGb from '../../../../assets/images/userManual/mobile-fueling-demo-6_en-GB.png';
import parking1EnGb from '../../../../assets/images/userManual/parking-1_en-GB.png';
import parking2EnGb from '../../../../assets/images/userManual/parking-2_en-GB.png';
import parking3EnGb from '../../../../assets/images/userManual/parking-3_en-GB.png';
import parking4EnGb from '../../../../assets/images/userManual/parking-4_en-GB.png';
import parkingApp1EnGb from '../../../../assets/images/userManual/parking-app-1_en-GB.png';
import parkingApp2EnGb from '../../../../assets/images/userManual/parking-app-2_en-GB.png';
import parkingApp3EnGb from '../../../../assets/images/userManual/parking-app-3_en-GB.png';
import parkingApp4EnGb from '../../../../assets/images/userManual/parking-app-4_en-GB.png';

import playStore from '../../../../assets/images/userManual/play-store.svg';
import appStore from '../../../../assets/images/userManual/app-store.svg';

export const imageKeys = {
  welcome: 'welcome',
  vehicleOverview1: 'vehicleOverview1',
  vehicleOverview2: 'vehicleOverview2',
  addingServiceCard1: 'addingServiceCard1',
  addingServiceCard2: 'addingServiceCard2',
  addingServiceCard3: 'addingServiceCard3',
  addingServiceCard4: 'addingServiceCard4',
  addingServiceCard5: 'addingServiceCard5',
  addingServiceCard6: 'addingServiceCard6',
  activatingCard1: 'activatingCard1',
  activatingCard2: 'activatingCard2',
  dashboardOverview1: 'dashboardOverview1',
  dashboardOverviewCard1: 'dashboardOverviewCard1',
  dashboardOverviewCard2: 'dashboardOverviewCard2',
  dashboardOverviewCard3: 'dashboardOverviewCard3',
  dashboardOverviewCard4: 'dashboardOverviewCard4',
  dashboardOverviewCard5: 'dashboardOverviewCard5',
  dashboardOverviewCard6: 'dashboardOverviewCard6',
  dashboardOverviewCard7: 'dashboardOverviewCard7',
  displayTransactions1: 'displayTransactions1',
  displayTransactions2: 'displayTransactions2',
  displayTransactions3: 'displayTransactions3',
  bookingOverview1: 'bookingOverview1',
  bookingOverview2: 'bookingOverview2',
  mobileFueling1: 'mobileFueling1',
  mobileFueling2: 'mobileFueling2',
  mobileFueling3: 'mobileFueling3',
  mobileFueling4: 'mobileFueling4',
  manAppIcon: 'manAppIcon',
  manAppleStoreQr: 'manAppleStoreQr',
  manPlayStoreQr: 'manPlayStoreQr',
  mobileFuelingDemo1: 'mobileFuelingDemo1',
  mobileFuelingDemo2: 'mobileFuelingDemo2',
  mobileFuelingDemo3: 'mobileFuelingDemo3',
  mobileFuelingDemo4: 'mobileFuelingDemo4',
  mobileFuelingDemo5: 'mobileFuelingDemo5',
  mobileFuelingDemo6: 'mobileFuelingDemo6',
  parking1: 'parking1',
  parking2: 'parking2',
  parking3: 'parking3',
  parking4: 'parking4',
  parkingApp1: 'parkingApp1',
  parkingApp2: 'parkingApp2',
  parkingApp3: 'parkingApp3',
  parkingApp4: 'parkingApp4',
  playStore: 'playStore',
  appStore: 'appStore',
};

export const getImageAssetWithLocale = (
  keys: string,
  locale: string,
): string => {
  switch (keys + locale) {
    // de-DE
    case 'welcome' + 'de-DE': {
      return welcomeImageDeDe;
    }
    case 'vehicleOverview1' + 'de-DE': {
      return vehicleOverview1DeDE;
    }
    case 'vehicleOverview2' + 'de-DE': {
      return vehicleOverview2DeDE;
    }
    case 'addingServiceCard1' + 'de-DE': {
      return addingServiceCard1DeDE;
    }
    case 'addingServiceCard2' + 'de-DE': {
      return addingServiceCard2DeDE;
    }
    case 'addingServiceCard3' + 'de-DE': {
      return addingServiceCard3DeDE;
    }
    case 'addingServiceCard4' + 'de-DE': {
      return addingServiceCard4DeDE;
    }
    case 'addingServiceCard5' + 'de-DE': {
      return addingServiceCard5DeDE;
    }
    case 'addingServiceCard6' + 'de-DE': {
      return addingServiceCard6DeDE;
    }
    case 'activatingCard1' + 'de-DE': {
      return activatingService1DeDE;
    }
    case 'activatingCard2' + 'de-DE': {
      return activatingService2DeDE;
    }
    case 'dashboardOverview1' + 'de-DE': {
      return dashboardOverview1DeDE;
    }
    case 'dashboardOverviewCard1' + 'de-DE': {
      return dashboardOverviewCard1DeDE;
    }
    case 'dashboardOverviewCard2' + 'de-DE': {
      return dashboardOverviewCard2DeDE;
    }
    case 'dashboardOverviewCard3' + 'de-DE': {
      return dashboardOverviewCard3DeDE;
    }
    case 'dashboardOverviewCard4' + 'de-DE': {
      return dashboardOverviewCard4DeDE;
    }
    case 'dashboardOverviewCard5' + 'de-DE': {
      return dashboardOverviewCard5DeDE;
    }
    case 'dashboardOverviewCard6' + 'de-DE': {
      return dashboardOverviewCard6DeDE;
    }
    case 'dashboardOverviewCard7' + 'de-DE': {
      return dashboardOverviewCard7DeDE;
    }
    case 'displayTransactions1' + 'de-DE': {
      return displayTransactions1DeDE;
    }
    case 'displayTransactions2' + 'de-DE': {
      return displayTransactions2DeDE;
    }
    case 'displayTransactions3' + 'de-DE': {
      return displayTransactions3DeDE;
    }
    case 'bookingOverview1' + 'de-DE': {
      return bookingOverview1DeDE;
    }
    case 'bookingOverview2' + 'de-DE': {
      return bookingOverview2DeDE;
    }
    case 'mobileFueling1' + 'de-DE': {
      return mobileFueling1DeDE;
    }
    case 'mobileFueling2' + 'de-DE': {
      return mobileFueling2DeDE;
    }
    case 'mobileFueling3' + 'de-DE': {
      return mobileFueling3DeDE;
    }
    case 'mobileFueling4' + 'de-DE': {
      return mobileFueling4DeDE;
    }
    case 'mobileFuelingDemo1' + 'de-DE': {
      return mobileFuelingDemo1DeDE;
    }
    case 'mobileFuelingDemo2' + 'de-DE': {
      return mobileFuelingDemo2DeDE;
    }
    case 'mobileFuelingDemo3' + 'de-DE': {
      return mobileFuelingDemo3DeDE;
    }
    case 'mobileFuelingDemo4' + 'de-DE': {
      return mobileFuelingDemo4DeDE;
    }
    case 'mobileFuelingDemo5' + 'de-DE': {
      return mobileFuelingDemo5DeDE;
    }
    case 'mobileFuelingDemo6' + 'de-DE': {
      return mobileFuelingDemo6DeDE;
    }
    case 'parking1' + 'de-DE': {
      return parking1DeDE;
    }
    case 'parking2' + 'de-DE': {
      return parking2DeDE;
    }
    case 'parking3' + 'de-DE': {
      return parking3DeDE;
    }
    case 'parking4' + 'de-DE': {
      return parking4DeDE;
    }
    case 'parkingApp1' + 'de-DE': {
      return parkingApp1DeDE;
    }
    case 'parkingApp2' + 'de-DE': {
      return parkingApp2DeDE;
    }
    case 'parkingApp3' + 'de-DE': {
      return parkingApp3DeDE;
    }
    case 'parkingApp4' + 'de-DE': {
      return parkingApp4DeDE;
    }

    // en-GB
    case 'welcome' + 'en-GB': {
      return welcomeImageEnGb;
    }
    case 'vehicleOverview1' + 'en-GB': {
      return vehicleOverview1EnGb;
    }
    case 'vehicleOverview2' + 'en-GB': {
      return vehicleOverview2EnGb;
    }
    case 'addingServiceCard1' + 'en-GB': {
      return addingServiceCard1EnGb;
    }
    case 'addingServiceCard2' + 'en-GB': {
      return addingServiceCard2EnGb;
    }
    case 'addingServiceCard3' + 'en-GB': {
      return addingServiceCard3EnGb;
    }
    case 'addingServiceCard4' + 'en-GB': {
      return addingServiceCard4EnGb;
    }
    case 'addingServiceCard5' + 'en-GB': {
      return addingServiceCard5EnGb;
    }
    case 'addingServiceCard6' + 'en-GB': {
      return addingServiceCard6EnGb;
    }
    case 'activatingCard1' + 'en-GB': {
      return activatingService1EnGb;
    }
    case 'activatingCard2' + 'en-GB': {
      return activatingService2EnGb;
    }
    case 'dashboardOverview1' + 'en-GB': {
      return dashboardOverview1EnGb;
    }
    case 'dashboardOverviewCard1' + 'en-GB': {
      return dashboardOverviewCard1EnGb;
    }
    case 'dashboardOverviewCard2' + 'en-GB': {
      return dashboardOverviewCard2EnGb;
    }
    case 'dashboardOverviewCard3' + 'en-GB': {
      return dashboardOverviewCard3EnGb;
    }
    case 'dashboardOverviewCard4' + 'en-GB': {
      return dashboardOverviewCard4EnGb;
    }
    case 'dashboardOverviewCard5' + 'en-GB': {
      return dashboardOverviewCard5EnGb;
    }
    case 'dashboardOverviewCard6' + 'en-GB': {
      return dashboardOverviewCard6EnGb;
    }
    case 'dashboardOverviewCard7' + 'en-GB': {
      return dashboardOverviewCard7EnGb;
    }
    case 'displayTransactions1' + 'en-GB': {
      return displayTransactions1EnGb;
    }
    case 'displayTransactions2' + 'en-GB': {
      return displayTransactions2EnGb;
    }
    case 'displayTransactions3' + 'en-GB': {
      return displayTransactions3EnGb;
    }
    case 'bookingOverview1' + 'en-GB': {
      return bookingOverview1EnGb;
    }
    case 'bookingOverview2' + 'en-GB': {
      return bookingOverview2EnGb;
    }
    case 'mobileFueling1' + 'en-GB': {
      return mobileFueling1EnGb;
    }
    case 'mobileFueling2' + 'en-GB': {
      return mobileFueling2EnGb;
    }
    case 'mobileFueling3' + 'en-GB': {
      return mobileFueling3EnGb;
    }
    case 'mobileFueling4' + 'en-GB': {
      return mobileFueling4EnGb;
    }
    case 'mobileFuelingDemo1' + 'en-GB': {
      return mobileFuelingDemo1EnGb;
    }
    case 'mobileFuelingDemo2' + 'en-GB': {
      return mobileFuelingDemo2EnGb;
    }
    case 'mobileFuelingDemo3' + 'en-GB': {
      return mobileFuelingDemo3EnGb;
    }
    case 'mobileFuelingDemo4' + 'en-GB': {
      return mobileFuelingDemo4EnGb;
    }
    case 'mobileFuelingDemo5' + 'en-GB': {
      return mobileFuelingDemo5EnGb;
    }
    case 'mobileFuelingDemo6' + 'en-GB': {
      return mobileFuelingDemo6EnGb;
    }
    case 'parking1' + 'en-GB': {
      return parking1EnGb;
    }
    case 'parking2' + 'en-GB': {
      return parking2EnGb;
    }
    case 'parking3' + 'en-GB': {
      return parking3EnGb;
    }
    case 'parking4' + 'en-GB': {
      return parking4EnGb;
    }
    case 'parkingApp1' + 'en-GB': {
      return parkingApp1EnGb;
    }
    case 'parkingApp2' + 'en-GB': {
      return parkingApp2EnGb;
    }
    case 'parkingApp3' + 'en-GB': {
      return parkingApp3EnGb;
    }
    case 'parkingApp4' + 'en-GB': {
      return parkingApp4EnGb;
    }

    // Common
    case 'manAppIcon' + 'en-GB' || 'manAppIcon' + 'de-DE': {
      return manAppIcon;
    }
    case 'manAppleStoreQr' + 'en-GB' || 'manAppleStoreQr' + 'de-DE': {
      return manAppleStoreQr;
    }
    case 'manPlayStoreQr' + 'en-GB' || 'manPlayStoreQr' + 'de-DE': {
      return manPlayStoreQr;
    }
    case 'playStore' + 'en-GB' || 'playStore' + 'de-DE': {
      return playStore;
    }
    case 'appStore' + 'en-GB' || 'appleStore' + 'de-DE': {
      return appStore;
    }

    default:
      return '';
  }
};
