import { createRioUserManager } from './rioUserManager';
import { appConfiguration } from '../configuration/appConfiguration';
import { User } from 'oidc-client-ts';
import { User as SimplePayUser } from './loginSlice';
import { routeStorage } from '../routes/routeStorage';
import { EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { DEFAULT_LOCALE } from '../i18n/i18n';

const mapUser = (user: User): SimplePayUser => {
  const accountId = user.profile?.account || user.profile['cognito:username'];
  return {
    accessToken: user.access_token,
    accountId: accountId as string,
    locale: user.profile.locale || DEFAULT_LOCALE,
  };
};

export const MOCK_USER: SimplePayUser = {
  accessToken: 'mocked-access-token',
  accountId: '8ef04c71-8105-4bfb-a97c-a70db9d66975',
  locale: DEFAULT_LOCALE,
};

export const loginRio = async (): Promise<SimplePayUser | undefined> => {
  if (appConfiguration.login.mock) {
    return MOCK_USER;
  }

  const userManager = createRioUserManager();

  const isRedirectedFromRioAuthServer =
    window.location.href.startsWith(appConfiguration.login.redirectUri) &&
    !window.location.search.includes('source=');

  if (isRedirectedFromRioAuthServer) {
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, () =>
      userManager.signinSilent(),
    );

    const user = await userManager.signinRedirectCallback();
    return mapUser(user);
  }

  // Will be picked up after redirect from OAuth Server
  routeStorage.saveRoute(window.location.pathname + window.location.search);

  await userManager.signinRedirect();
  return Promise.resolve(undefined);
};
