import {
  enhancedApi,
  PaymentMethod,
  PaymentPartnerType,
  useAddPaymentMethodMutation,
  Vehicle,
} from '../../../../../codegen/vehicles';
import React from 'react';
import { useCustomIntl } from '../../../../../i18n/i18n';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks';
import { useVehicleDialogFormContext } from '../ServiceConfigurationForm';
import {
  addNewServiceCardDialogError,
  closeAddNewServiceCardDialog,
  DialogMode,
  getSelectedVehicleId,
  isAddNewServiceCardDialogOpen,
} from '../../../state/onboardingUiSlice';
import { DialogFooter } from '../DialogFooter';
import { ServiceCardForm } from './ServiceCardForm';
import moment from 'moment';
import { ErrorMessagePanel } from '../../../../../components/ErrorMessagePanel';
import { useEdenredToken } from '../../../../../login/external/edenredUserManager';
import { isNil } from 'lodash';
import { ERROR_CODE_TO_TRANSLATION_KEY } from '../../../../../i18n/enumMappings';
import { BackendErrors } from '../../../../../models';

export const AddNewServiceCardDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();
  const showDialog = useAppSelector(isAddNewServiceCardDialogOpen);
  const selectedVehicleId = useAppSelector(getSelectedVehicleId);
  const externalLoginError = useAppSelector(addNewServiceCardDialogError);
  const {
    getNewServiceCard,
    getPaymentMethods,
    setPaymentMethods,
    trigger,
    formState: { isDirty, isValid },
  } = useVehicleDialogFormContext();
  const [
    addPaymentMethod,
    { error: addPaymentMethodError, isLoading: isAddNewCardLoading, reset },
  ] = useAddPaymentMethodMutation();
  const edenredToken = useEdenredToken();

  if (!showDialog || !selectedVehicleId) {
    return <></>;
  }

  const isPaymentCardProviderLoginRequired =
    getNewServiceCard()?.paymentPartner === PaymentPartnerType.UtaPayment &&
    !edenredToken;

  const handleClose = () => {
    reset();
    dispatch(closeAddNewServiceCardDialog());
  };

  const handleAddNewCard = async () => {
    const isValidationOk = await trigger(
      ['newServiceCard.cardNumber', 'newServiceCard.expiryDate'],
      {
        shouldFocus: true,
      },
    );

    if (isValidationOk) {
      const newServiceCard = getNewServiceCard();
      const result = await addPaymentMethod({
        vehicleId: selectedVehicleId,
        addPaymentMethodRequest: {
          type: newServiceCard.type,
          paymentPartner: newServiceCard.paymentPartner,
          cardNumber: newServiceCard.cardNumber,
          expiry: moment(newServiceCard.expiryDate, 'MM/YYYY').format('MM-YY'),
          onboardingDetails:
            newServiceCard.paymentPartner === PaymentPartnerType.UtaPayment &&
            !isNil(edenredToken)
              ? { userToken: edenredToken }
              : undefined,
        },
      });
      if ('error' in result) {
        return;
      }
      const paymentMethods: PaymentMethod[] = getPaymentMethods();
      const paymentMethod = result.data;
      paymentMethods.push({
        ...paymentMethod,
        expiry: moment(paymentMethod.expiry, 'MM-YY').format('MM/YYYY'),
      });
      setPaymentMethods(paymentMethods);
      dispatch(
        enhancedApi.util.updateQueryData(
          'getVehicles',
          undefined,
          (draftVehicles) => {
            draftVehicles.items = draftVehicles.items.map((v: Vehicle) => {
              if (v.id === selectedVehicleId) {
                v.paymentMethods = paymentMethods;
              }
              return v;
            });
          },
        ),
      );
      handleClose();
    }
  };

  const renderAddNewCardBody = () => (
    <>
      {(addPaymentMethodError || externalLoginError) && (
        <ErrorMessagePanel
          message={
            ERROR_CODE_TO_TRANSLATION_KEY[
              addPaymentMethodError?.data?.errorCode as BackendErrors
            ]
          }
        />
      )}
      <ServiceCardForm />
    </>
  );

  return (
    <Dialog
      data-testid={'add-new-service-card-dialog'}
      useOverflow
      bsSize={Dialog.SIZE_SM}
      show
      onClose={handleClose}
      title={intl.formatMessage({
        id: 'onboarding.addServiceCard.dialog.title',
      })}
      body={renderAddNewCardBody()}
      footer={
        <DialogFooter
          showLegalText={false}
          dialogMode={DialogMode.SERVICES}
          onClose={handleClose}
          isSaveButtonEnabled={
            isValid && isDirty && !isPaymentCardProviderLoginRequired
          }
          onSave={handleAddNewCard}
          isLoading={isAddNewCardLoading}
        />
      }
    />
  );
};
