import React, { useEffect, useRef, useState } from 'react';
import { useTableFilteredBookings } from '../../state/bookings/bookingsFilterSlice';
import { BookingsTableToolbar } from './components/table/BookingsTableToolbar';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import classNames from 'classnames';
import { NoDataPlaceholder } from '../../components/NoDataPlaceholder';
import { BookingsTable } from './components/table/BookingsTable';
import { FleetMonitorLink } from './components/table/FleetMonitorLink';

const FULL_SCREEN_HEIGHT = '100vh';
const RIO_APP_PADDING = '30px';
const RESIZE_EVENT = 'resize';

export const BookingsPage: React.FC = () => {
  const {
    filteredBookings: bookings,
    isLoading,
    error,
  } = useTableFilteredBookings();
  const [distanceFromTableToTop, setDistanceFromTableToTop] = useState(0);

  const tableRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const showTwoColumns = bookings.length > 5;

  const calculateDistanceFromTableToTop = () => {
    const tableWrapper = document.getElementById(
      'bookings-body-bookings-table',
    );
    if (tableWrapper) {
      const distanceToTop = tableWrapper.getBoundingClientRect().top;
      setDistanceFromTableToTop(distanceToTop);
    }
  };

  useEffect(() => {
    calculateDistanceFromTableToTop();

    window.addEventListener(RESIZE_EVENT, calculateDistanceFromTableToTop);

    return () => {
      window.removeEventListener(RESIZE_EVENT, calculateDistanceFromTableToTop);
    };
  }, []);

  const updatePanelHeight = () => {
    if (showTwoColumns && tableRef.current && panelRef.current) {
      const tableHeight = tableRef.current.clientHeight;
      panelRef.current.style.height = `${tableHeight}px`;
    }
  };

  useEffect(() => {
    updatePanelHeight();

    window.addEventListener(RESIZE_EVENT, updatePanelHeight);

    return () => {
      window.removeEventListener(RESIZE_EVENT, updatePanelHeight);
    };
  }, [tableRef, panelRef, bookings]);

  const maxTableHeight = `calc(${FULL_SCREEN_HEIGHT} - ${distanceFromTableToTop}px - ${RIO_APP_PADDING})`;

  return (
    <div
      style={{
        height: showTwoColumns ? maxTableHeight : undefined,
      }}
    >
      <BookingsTableToolbar />
      <div className="row height-100pct">
        {isLoading ? (
          <Spinner isDoubleSized />
        ) : (
          <div
            className={classNames(
              showTwoColumns
                ? 'col-8 height-100pct'
                : 'col-12 margin-bottom-25',
              'display-flex',
              'flex-column',
            )}
          >
            {error ? (
              <NoDataPlaceholder className="max-width-100pct" />
            ) : (
              <BookingsTable
                maxTableHeight={maxTableHeight}
                tableRef={tableRef}
              />
            )}
          </div>
        )}
        <div
          className={classNames(
            showTwoColumns ? 'col-4' : 'col-12 max-height-250',
          )}
        >
          <div
            ref={panelRef}
            className={classNames({ 'max-height-250': !showTwoColumns })}
          >
            <FleetMonitorLink horizontal={!showTwoColumns} />
          </div>
        </div>
      </div>
    </div>
  );
};
