import {
  CustomFormattedMessage,
  TranslationsKeys,
} from '../../../../i18n/i18n';

type ChapterHeadlineProps = {
  messageId: TranslationsKeys;
};

export const ChapterHeadline = ({ messageId }: ChapterHeadlineProps) => (
  <div className={'col-xs-12 text-size-h2 margin-bottom-15'}>
    <CustomFormattedMessage id={messageId} />
  </div>
);
