import { ReactNode, useEffect } from 'react';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';

const NOTIFICATION_TIMEOUT = 999999;

const notifyInfoDismissible = (
  type: keyof typeof Notification,
  message: ReactNode,
) => {
  Notification[type](
    <span data-testid="dismissible-info-notification">
      {message}
      <span className="notification-close">
        <span className="rioglyph rioglyph-remove" />
      </span>
    </span>,
    undefined,
    NOTIFICATION_TIMEOUT,
  );
};

type EntityUpdateNotificationProps = {
  type?: keyof typeof Notification;
  showNotification: boolean;
  message: ReactNode;
  onClose?: () => void;
};

export const useEntityUpdateNotification = ({
  showNotification,
  message,
  type = 'info',
  onClose = () => {},
}: EntityUpdateNotificationProps) => {
  useEffect(() => {
    if (showNotification) {
      notifyInfoDismissible(type, message);
      onClose();
    }
  }, [showNotification]);
};
