import { CustomFormattedMessage } from '../../../../../i18n/i18n';
import { SIDEBAR_DATA_COLOR, SIDEBAR_LABEL_COLOR } from '../VehicleSidebar';
import { MaskedCardNumber } from '../../../../../components/MaskedCardNumber';
import { ServiceCardRepresentation } from '../../../../../components/dataRepresentation/ServiceCardRepresentation';
import { PaymentPartnerType } from '../../../../../codegen/vehicles';

type CardDetailsProps = {
  paymentPartner: PaymentPartnerType;
  lastFourDigits: string;
  expiry: string;
};

export const CardDetails = ({
  paymentPartner,
  lastFourDigits,
  expiry,
}: CardDetailsProps) => {
  return (
    <>
      <tr>
        <td className={`${SIDEBAR_LABEL_COLOR}`}>
          <CustomFormattedMessage id={'common.card.type'} />
        </td>
        <td className={`${SIDEBAR_DATA_COLOR}`}>
          <ServiceCardRepresentation paymentPartner={paymentPartner} />
        </td>
      </tr>
      <tr>
        <td className={`${SIDEBAR_LABEL_COLOR}`}>
          <CustomFormattedMessage id={'common.card.number'} />
        </td>
        <td className={`${SIDEBAR_DATA_COLOR}`}>
          <MaskedCardNumber lastFourDigits={lastFourDigits} />
        </td>
      </tr>
      <tr>
        <td className={`${SIDEBAR_LABEL_COLOR}`}>
          <CustomFormattedMessage id={'common.card.expiryDate'} />
        </td>
        <td className={`${SIDEBAR_DATA_COLOR}`}>{expiry}</td>
      </tr>
    </>
  );
};
