import { appConfiguration } from '../../configuration/appConfiguration';
import { User, UserManager } from 'oidc-client-ts';
import { useOidcUser } from './useOidcUser';

const TOKEN_EXPIRATION_LEEWAY_SECONDS = 60;

const mockEdenredUserManager: UserManager = (() => {
  let mockEdenredUser: User | null = null;

  // Cast to UserManager as we only implement class as far as needed in mock case
  return {
    getUser: async () => mockEdenredUser,
    signinRedirect: async () => {
      window.open(`${window.location.origin}/redirect?source=edenred`, '_self');
    },
    signinRedirectCallback: async () => {
      mockEdenredUser = {
        access_token: 'local-edenred-mock-access-token',
        expires_in: 7 * 24 * 60 * 60,
        expired: false,
      } as User;
      return mockEdenredUser;
    },
  } as UserManager;
})();

export const edenredUserManager: UserManager = appConfiguration
  .paymentCardProviderLogin.edenred.mock
  ? mockEdenredUserManager
  : new UserManager({
      authority: appConfiguration.paymentCardProviderLogin.edenred.authority!,
      client_id: appConfiguration.paymentCardProviderLogin.edenred.clientId!,
      client_secret:
        appConfiguration.paymentCardProviderLogin.edenred.clientSecret!,
      redirect_uri:
        appConfiguration.paymentCardProviderLogin.edenred.redirectUri!,
      response_type: 'code',
      scope: appConfiguration.paymentCardProviderLogin.edenred.scopes!,
      acr_values: appConfiguration.paymentCardProviderLogin.edenred.acr_values!,
      loadUserInfo: false,
    });

export const useEdenredToken = (): string | undefined => {
  const user = useOidcUser(edenredUserManager);

  if (
    user &&
    !user.expired &&
    (user.expires_in || 0) > TOKEN_EXPIRATION_LEEWAY_SECONDS
  ) {
    return user.access_token;
  }

  return undefined;
};
