export const REGEX_UUID =
  '[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}';

export const VEHICLE_WITHOUT_ACTIVE_SERVICE = 0;
export const VEHICLE_WITH_ACTIVE_PARKING_SERVICE = 1;
export const VEHICLE_WITH_ACTIVE_FUELING_SERVICE = 3;
export const VEHICLE_WITH_MULTIPLE_ACTIVE_SERVICES = 4;
export const VEHICLE_WITH_ACTIVE_WASHING_SERVICE = 5;
export const VEHICLE_WITH_ACTIVE_BREAKDOWN_SERVICE = 6;
export const VEHICLE_WITH_SERVICE_CARDS_FOR_EACH_SERVICE = 7;

export const FUELING_SERVICE = 'Fuelling';
export const BREAKDOWN_SERVICE = 'Repair & Maintenance';
export const PARKING_SERVICE = 'Parking';
export const WASHING_SERVICE = 'Washing';
export const CHARGING_SERVICE = 'Charging';
export const OTHER_SERVICE = 'Other operating costs';

export const MOCK_FUELING_PARTNER = 'MAN Pump & Pay';
export const MOBILE_24 = 'Mobile 24';
export const TRAVIS = 'Travis';
export const UTA = 'UTA';

export const BREAKDOWN = 'BREAKDOWN';
export const FUELING = 'FUELING';
export const PARKING = 'PARKING';
export const WASHING = 'WASHING';
export const CHARGING = 'CHARGING';
export const OTHER = 'OTHER';

export const TEST_FUEL_CARD_TYPE = 'MAN fuel card';

export type ServiceCardDetails = {
  lastFourDigits: string;
  expirationDate: string;
  authorizationAmount: string;
  fuelCardType: string;
};

export const DEFAULT_SERVICE_CARD: ServiceCardDetails = {
  lastFourDigits: '9876',
  expirationDate: '06/2028',
  authorizationAmount: '175',
  fuelCardType: TEST_FUEL_CARD_TYPE,
};

export const BREAKDOWN_SERVICE_CARD: ServiceCardDetails = {
  lastFourDigits: '5432',
  expirationDate: '03/2029',
  authorizationAmount: '122',
  fuelCardType: TEST_FUEL_CARD_TYPE,
};

export const WASHING_SERVICE_CARD: ServiceCardDetails = {
  lastFourDigits: '0001',
  expirationDate: '01/2032',
  authorizationAmount: '99.99',
  fuelCardType: TEST_FUEL_CARD_TYPE,
};

export const PARKING_SERVICE_CARD: ServiceCardDetails = {
  lastFourDigits: '1604',
  expirationDate: '04/2036',
  authorizationAmount: '500.00',
  fuelCardType: TEST_FUEL_CARD_TYPE,
};

export const CHARGING_SERVICE_CARD: ServiceCardDetails = {
  lastFourDigits: '1678',
  expirationDate: '04/2036',
  authorizationAmount: '500.00',
  fuelCardType: TEST_FUEL_CARD_TYPE,
};

export const OTHER_SERVICE_CARD: ServiceCardDetails = {
  lastFourDigits: '5478',
  expirationDate: '04/2036',
  authorizationAmount: '500.00',
  fuelCardType: TEST_FUEL_CARD_TYPE,
};

export const formatMaskedFuelCardNumber = (lastFourDigits: string) =>
  `**** **** **** ***${lastFourDigits[0]} ${lastFourDigits.slice(1)}`;
