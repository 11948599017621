import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AUTH_TYPE, AWSAppSyncClient } from 'aws-appsync/lib';
import { DocumentNode, GraphQLError } from 'graphql';
import { appConfiguration } from '../../configuration/appConfiguration';
import { User } from '../../login/loginSlice';
import {
  GRAPHQL_ERROR_MESSAGE_KEY,
  GRAPHQL_ERROR_TITLE_KEY,
  GRAPHQL_SUBSCRIPTION_ERROR_MESSAGE_KEY,
  GRAPHQL_SUBSCRIPTION_ERROR_TITLE_KEY,
  notifyError,
} from '../../components/showNotification';
import { IdGetterObj } from 'apollo-cache-inmemory/lib/types';

export enum GraphqlRequestType {
  QUERY = 'QUERY',
  MUTATION = 'MUTATION',
}

export type Request = {
  type: GraphqlRequestType;
  definition: DocumentNode;
};

export type Subscription = {
  definition: DocumentNode;
};

export type GraphQlOperation<T extends Request | Subscription> = {
  user: User;
  request: T;
  requestParams?: object;
};

export const createAppSyncClient = (user: User) => {
  return new AWSAppSyncClient({
    url: appConfiguration.appSync.url,
    region: appConfiguration.appSync.region,
    disableOffline: true,
    auth: {
      type: AUTH_TYPE.OPENID_CONNECT,
      jwtToken: `Bearer ${user.accessToken}`,
    },
    // disable caching, so objects with nullable IDs can be processed
    cacheOptions: {
      dataIdFromObject: (value: IdGetterObj) => null,
    },
  });
};

const query = (requestData: GraphQlOperation<Request>) => {
  const { user, request, requestParams } = requestData;
  const client = createAppSyncClient(user);
  return client.query({
    query: request.definition,
    variables: requestParams,
  });
};

const mutate = (requestData: GraphQlOperation<Request>) => {
  const { user, request, requestParams } = requestData;
  const client = createAppSyncClient(user);
  return client.mutate({
    mutation: request.definition,
    variables: requestParams,
  });
};

const performRequest = (requestData: GraphQlOperation<Request>) => {
  switch (requestData.request.type) {
    case GraphqlRequestType.QUERY:
      return query(requestData);
    case GraphqlRequestType.MUTATION:
      return mutate(requestData);
  }
};

const handleErrors = (errors: readonly GraphQLError[]) => {
  notifyError(GRAPHQL_ERROR_TITLE_KEY, GRAPHQL_ERROR_MESSAGE_KEY);

  return { error: errors.map((error) => error.message) };
};

export const graphqlBaseQuery: BaseQueryFn<
  GraphQlOperation<Request>,
  unknown,
  string[]
> = async (requestData) => {
  try {
    const result = await performRequest(requestData);

    if (result.errors?.length) {
      return handleErrors(result.errors);
    }

    return { data: result.data };
  } catch (error: any) {
    const errors = error.graphQLErrors;

    const graphQlErrors = errors ?? [
      new GraphQLError('An unexpected error occurred'),
    ];

    return handleErrors(graphQlErrors);
  }
};

export const graphqlBaseSubscription: <T>(
  subscriptionData: GraphQlOperation<Subscription>,
  updateHandler: (value: T) => void,
) => void = (subscriptionData, updateHandler) => {
  try {
    const { user, request, requestParams } = subscriptionData;

    const client = createAppSyncClient(user);
    const observable = client.subscribe({
      query: request.definition,
      variables: requestParams,
    });

    observable.subscribe({
      next: updateHandler,
      error: () =>
        notifyError(
          GRAPHQL_SUBSCRIPTION_ERROR_TITLE_KEY,
          GRAPHQL_SUBSCRIPTION_ERROR_MESSAGE_KEY,
        ),
    });
  } catch (error) {
    notifyError(
      GRAPHQL_SUBSCRIPTION_ERROR_TITLE_KEY,
      GRAPHQL_SUBSCRIPTION_ERROR_MESSAGE_KEY,
    );
  }
};
