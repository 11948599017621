import { ReactElement } from 'react';
import { TableHeaderCell } from './TableHeaderCell';
import { SortDirection } from '../../models';

export type HeaderColumn<T> = {
  id: keyof T;
  label: string;
};

export type TableSorting<T> = {
  sortBy?: keyof T;
  sortDirection?: SortDirection;
  onSortChanged: (sortBy: keyof T, sortDirection: SortDirection) => void;
};

type TableHeaderProps<T> = {
  columns: HeaderColumn<T>[];
  sorting?: TableSorting<T>;
};

export const TableHeader = <T extends object>({
  columns,
  sorting,
}: TableHeaderProps<T>): ReactElement | null => {
  const handleSortClicked = (
    column: HeaderColumn<T>,
    nextSortDirection: SortDirection,
  ) => {
    sorting?.onSortChanged(column.id, nextSortDirection);
  };

  const getSorting = (column: HeaderColumn<T>) => {
    return sorting
      ? {
          sortBy: sorting.sortBy,
          sortDirection: sorting.sortDirection ?? SortDirection.ASCENDING,
          onSortClick: (nextSortDirection: SortDirection) =>
            handleSortClicked(column, nextSortDirection),
        }
      : undefined;
  };

  return (
    <thead>
      <tr>
        {columns.map((column) => {
          return (
            <TableHeaderCell
              key={column.id.toString()}
              id={column.id}
              label={column.label}
              sorting={getSorting(column)}
            />
          );
        })}
        <th className="width-50" />
      </tr>
    </thead>
  );
};
