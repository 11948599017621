import React from 'react';
import ListMenu from '@rio-cloud/rio-uikit/ListMenu';
import { ChapterMenuLink } from './ChapterMenuLink';
import {
  userManualDialogChapters,
  UserManualDialogChaptersKeys,
} from '../../../state/dialogs/chapters';
import { CustomFormattedMessage, TranslationsKeys } from '../../../i18n/i18n';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
  getOpenChapter,
  openUserManualDialog,
} from '../../../state/dialogs/useUserManualDialog';
import { appConfiguration } from '../../../configuration/appConfiguration';

type ChapterGroup = {
  title: TranslationsKeys;
  chapters: UserManualDialogChaptersKeys[];
};

const chapterTitles: {
  [key in UserManualDialogChaptersKeys]: TranslationsKeys;
} = {
  welcome: 'userManual.chapter.welcome',
  vehicleOverview: 'userManual.chapter.vehicleOverview',
  addingServiceCards: 'userManual.chapter.addingServiceCard',
  activatingServices: 'userManual.chapter.activatingAService',
  dashboardFunctions: 'userManual.chapter.dashboardFunctions',
  displayTransactions: 'userManual.chapter.displayTransactions',
  bookingOverview: 'userManual.chapter.bookingOverview',
  mobileFueling: 'userManual.chapter.mobileFueling',
  parking: 'userManual.chapter.parking',
  repairMaintenance: 'userManual.chapter.repairMaintenance',
  washing: 'userManual.chapter.washing',
  charging: 'userManual.chapter.charging',
  otherOperatingCosts: 'userManual.chapter.otherOperatingCosts',
};

export const UserManualDialogMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const activeChapter = useAppSelector(getOpenChapter);

  const servicesChapters = [
    userManualDialogChapters.mobileFueling,
    userManualDialogChapters.parking,
    userManualDialogChapters.washing,
    userManualDialogChapters.repairMaintenance,
    userManualDialogChapters.otherOperatingCosts,
  ];

  if (appConfiguration.featureToggles.enableTcsCharging) {
    servicesChapters.splice(4, 0, userManualDialogChapters.charging);
  }

  const chapterGroups: ChapterGroup[] = [
    {
      title: 'userManual.chapterGroup.ourService',
      chapters: [userManualDialogChapters.welcome],
    },
    {
      title: 'userManual.chapterGroup.cardManagement',
      chapters: [
        userManualDialogChapters.vehicleOverview,
        userManualDialogChapters.addingServiceCards,
        userManualDialogChapters.activatingServices,
      ],
    },
    {
      title: 'userManual.chapterGroup.tco',
      chapters: [userManualDialogChapters.dashboardFunctions],
    },
    {
      title: 'userManual.chapterGroup.transactionOverview',
      chapters: [userManualDialogChapters.displayTransactions],
    },
    {
      title: 'userManual.chapterGroup.bookings',
      chapters: [userManualDialogChapters.bookingOverview],
    },
    {
      title: 'userManual.chapterGroup.services',
      chapters: servicesChapters,
    },
  ];

  const buildChapterGroup = (chapterGroup: ChapterGroup) => {
    return {
      group: <CustomFormattedMessage id={chapterGroup.title} />,
      navItems: chapterGroup.chapters.map((chapter) => ({
        key: chapter,
        item: (
          <ChapterMenuLink
            chapter={chapter}
            isActive={activeChapter === chapter}
            onClick={() => handleChapterSelected(chapter)}
            title={chapterTitles[chapter]}
          />
        ),
      })),
    };
  };

  const handleChapterSelected = (chapter: UserManualDialogChaptersKeys) => {
    dispatch(openUserManualDialog(chapter));
  };

  return (
    <ListMenu
      data-testid={'user-manual-menu'}
      menuItems={chapterGroups.map((chapterGroup) =>
        buildChapterGroup(chapterGroup),
      )}
      groupClassName={'min-width-200 padding-left-0'}
    />
  );
};
