import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../state/store';
import {
  PaymentPartnerType,
  ServiceActivation,
} from '../../../codegen/vehicles';

export enum DialogMode {
  SERVICES = 'SERVICES',
  SERVICE_CARDS = 'SERVICE_CARDS',
}

export type AddNewServiceDialogPayload = {
  paymentPartnerType: PaymentPartnerType;
  externalLoginError?: string;
};

type RemoveConfirmationDialogPayload = {
  serviceActivations: ServiceActivation[];
  paymentMethodId: string;
};

type OnboardingState = {
  selectedVehicle?: string;
  sidebar: {
    open: boolean;
  };
  serviceConfigurationDialog: {
    open: boolean;
    dialogMode: DialogMode;
  };
  addNewServiceCardDialog: {
    open: boolean;
    selectedPaymentPartnerType: PaymentPartnerType;
    externalLoginError?: string;
  };
  removeConfirmationDialog: {
    open: boolean;
    serviceActivations: ServiceActivation[];
    paymentMethodId: string;
  };
};

const initialState: OnboardingState = {
  sidebar: {
    open: false,
  },
  serviceConfigurationDialog: {
    open: false,
    dialogMode: DialogMode.SERVICES,
  },
  addNewServiceCardDialog: {
    open: false,
    selectedPaymentPartnerType: PaymentPartnerType.MockFuelingPayment,
    externalLoginError: undefined,
  },
  removeConfirmationDialog: {
    open: false,
    serviceActivations: [],
    paymentMethodId: '',
  },
  selectedVehicle: undefined,
};

export const onboardingUiSlice = createSlice({
  name: 'onboardingPage',
  initialState,
  reducers: {
    setSelectedVehicleId: (state, action: PayloadAction<string>) => {
      state.selectedVehicle = action.payload;
    },
    openSidebar: (state) => {
      if (state.selectedVehicle) {
        state.sidebar.open = true;
      }
    },
    closeSidebar: (state) => {
      state.sidebar.open = false;
    },
    openConfigurationDialog: (state, action: PayloadAction<DialogMode>) => {
      if (!state.selectedVehicle) {
        return;
      }
      state.serviceConfigurationDialog.dialogMode = action.payload;
      state.sidebar.open = false;
      state.serviceConfigurationDialog.open = true;
    },
    closeConfigurationDialog: (state) => {
      state.serviceConfigurationDialog.open = false;
      state.selectedVehicle = undefined;
    },
    setDialogMode: (state, action: PayloadAction<DialogMode>) => {
      state.serviceConfigurationDialog.dialogMode = action.payload;
    },
    openRemoveConfirmationDialog: (
      state,
      action: PayloadAction<RemoveConfirmationDialogPayload>,
    ) => {
      state.removeConfirmationDialog.open = true;
      state.removeConfirmationDialog.serviceActivations =
        action.payload.serviceActivations;
      state.removeConfirmationDialog.paymentMethodId =
        action.payload.paymentMethodId;
    },
    closeRemoveConfirmationDialog: (state) => {
      state.removeConfirmationDialog.open = false;
      state.removeConfirmationDialog.serviceActivations = [];
    },
    openAddNewServiceCardDialog: (
      state,
      action: PayloadAction<AddNewServiceDialogPayload>,
    ) => {
      state.addNewServiceCardDialog.open = true;
      const addNewServiceDialogParameter = action.payload;
      state.addNewServiceCardDialog.selectedPaymentPartnerType =
        addNewServiceDialogParameter.paymentPartnerType;
      state.addNewServiceCardDialog.externalLoginError =
        addNewServiceDialogParameter.externalLoginError;
    },
    setPaymentPartnerType: (
      state,
      action: PayloadAction<PaymentPartnerType>,
    ) => {
      state.addNewServiceCardDialog.selectedPaymentPartnerType = action.payload;
    },
    closeAddNewServiceCardDialog: (state) => {
      state.addNewServiceCardDialog.open = false;
    },
  },
});

export const {
  setSelectedVehicleId,
  openSidebar,
  openConfigurationDialog,
  closeConfigurationDialog,
  setDialogMode,
  openRemoveConfirmationDialog,
  closeRemoveConfirmationDialog,
  closeSidebar,
  openAddNewServiceCardDialog,
  setPaymentPartnerType,
  closeAddNewServiceCardDialog,
} = onboardingUiSlice.actions;

export const getSelectedVehicleId = (state: RootState) =>
  state.onboardingUiState.selectedVehicle;
export const isConfigurationDialogOpen = (state: RootState) =>
  state.onboardingUiState.serviceConfigurationDialog.open;
export const getDialogMode = (state: RootState) =>
  state.onboardingUiState.serviceConfigurationDialog.dialogMode;
export const isAddNewServiceCardDialogOpen = (state: RootState) =>
  state.onboardingUiState.addNewServiceCardDialog.open;
export const addNewServiceCardDialogError = (state: RootState) =>
  state.onboardingUiState.addNewServiceCardDialog.externalLoginError;
export const getPaymentPartnerType = (state: RootState) =>
  state.onboardingUiState.addNewServiceCardDialog.selectedPaymentPartnerType;
export const getRemoveConfirmationDialog = (state: RootState) =>
  state.onboardingUiState.removeConfirmationDialog;
export const isSidebarOpen = (state: RootState) =>
  state.onboardingUiState.sidebar.open;
