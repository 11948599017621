import { ImageLoader } from '../../../ImageLoader';
import { useLocale } from '../../../../i18n/locale';
import { getImageAssetWithLocale, imageKeys } from './imageAssets';
import { BookingStatusLabel } from '../../../../pages/bookings/components/BookingStatusLabel';
import { BookingStatus } from '../../../../codegen/bookings';
import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';
import { ChapterSubHeadline } from '../chapterComponents/ChapterSubHeadline';

export const BookingsOverviewChapter: React.FC = () => {
  const { locale } = useLocale();

  const bookingOverview1 = getImageAssetWithLocale(
    imageKeys.bookingOverview1,
    locale,
  );
  const bookingOverview2 = getImageAssetWithLocale(
    imageKeys.bookingOverview2,
    locale,
  );

  return (
    <div className={'row'}>
      <ChapterHeadline messageId={'userManual.bookingOverview.headline'} />
      <ImageLoader
        src={bookingOverview1}
        className={'col-xs-12 margin-bottom-15'}
      />
      <ChapterText messageId={'userManual.bookingOverview.text'} />
      <ImageLoader
        src={bookingOverview2}
        className={'col-xs-12 margin-bottom-15'}
      />
      <ChapterSubHeadline
        messageId={'userManual.bookingOverview.status.subHeadline'}
      />
      <ChapterText messageId={'userManual.bookingOverview.status.text'} />

      <div className={'col-xs-12 margin-bottom-10'}>
        <div className={'col-xs-2'}>
          <BookingStatusLabel status={BookingStatus.Planned} />
        </div>
        <ChapterText
          colSize={10}
          marginBottom={0}
          messageId={'userManual.bookingOverview.status.planned.description'}
        />
      </div>
      <div className={'col-xs-12 margin-bottom-10'}>
        <div className={'col-xs-2'}>
          <BookingStatusLabel status={BookingStatus.InProgress} />
        </div>
        <ChapterText
          colSize={10}
          marginBottom={0}
          messageId={'userManual.bookingOverview.status.inProgress.description'}
        />
      </div>
      <div className={'col-xs-12 margin-bottom-10'}>
        <div className={'col-xs-2'}>
          <BookingStatusLabel status={BookingStatus.Completed} />
        </div>
        <ChapterText
          colSize={10}
          marginBottom={0}
          messageId={'userManual.bookingOverview.status.completed.description'}
        />
      </div>
      <div className={'col-xs-12 margin-bottom-10'}>
        <div className={'col-xs-2'}>
          <BookingStatusLabel status={BookingStatus.Canceled} />
        </div>
        <ChapterText
          colSize={10}
          marginBottom={0}
          messageId={'userManual.bookingOverview.status.cancelled.description'}
        />
      </div>
    </div>
  );
};
