import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { closeCancelDialogue } from '../../state/bookingsUiSlice';
import { useAppDispatch } from '../../../../state/hooks';
import classNames from 'classnames';

type CancelDialogFooterProps = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  onCancel: () => void;
};

export const CancelDialogFooter = ({
  isLoading,
  isSuccess,
  isError,
  onCancel,
}: CancelDialogFooterProps) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeCancelDialogue());
  };

  return (
    <div data-testid="booking-cancel-dialog-footer">
      {isError || isSuccess ? (
        <Button
          data-testid="booking-dialog-ok-button"
          bsStyle={'primary'}
          type={'submit'}
          onClick={handleClose}
        >
          <CustomFormattedMessage
            id={'common.booking.cancel.dialog.button.ok'}
          />
        </Button>
      ) : (
        <>
          <Button
            data-testid="booking-dialog-back-button"
            bsStyle={'default'}
            type={'button'}
            className={'margin-right-10'}
            onClick={handleClose}
            disabled={isLoading}
          >
            <CustomFormattedMessage
              id={'common.booking.cancel.dialog.button.back'}
            />
          </Button>
          <Button
            data-testid="booking-dialog-cancel-button"
            className={classNames({ 'btn-loading': isLoading })}
            bsStyle={'primary'}
            type={'submit'}
            onClick={onCancel}
            disabled={isLoading}
          >
            <CustomFormattedMessage
              id={'common.booking.cancel.dialog.button.cancel'}
            />
          </Button>
        </>
      )}
    </div>
  );
};
