import { PaymentPartnerType } from '../../codegen/vehicles';
import { useCustomIntl } from '../../i18n/i18n';
import utaLogo from '../../assets/images/partners/uta-logo.png';
import fuelingLogo from '../../assets/images/partners/fueling-placeholder.png';
import { PAYMENT_PARTNER_TO_TRANSLATION_KEY } from '../../i18n/enumMappings';

type PaymentPartnerIconProps = {
  paymentPartner: PaymentPartnerType;
  [key: string]: string;
};

const paymentPartnerToIcon: { [key in PaymentPartnerType]: string } = {
  UTA_PAYMENT: utaLogo,
  MOCK_FUELING_PAYMENT: fuelingLogo,
};

export const PaymentPartnerIcon: React.FC<PaymentPartnerIconProps> = ({
  paymentPartner,
  ...props
}: PaymentPartnerIconProps) => {
  const intl = useCustomIntl();

  return (
    <img
      alt={intl.formatMessage({
        id: PAYMENT_PARTNER_TO_TRANSLATION_KEY[paymentPartner],
      })}
      src={paymentPartnerToIcon[paymentPartner]}
      {...props}
    />
  );
};
