import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getUser } from '../../login/loginSlice';
import { useGetVehicleGroupsQuery } from '../vehicleGroups/vehicleGroupsSlice';
import { transactionsApiTemplateSlice } from './transactionsApiTemplateSlice';
import { DEFAULT_CURRENCY } from './transactionFilterSlice';
import {
  GetTransactionsRequestParams,
  injectedTransactionApiEndpoints,
} from './injectedTransactionApiEndpoints';

export const useTransactions = (request: GetTransactionsRequestParams = {}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const transactionsQuery = useGetTransactionsPaginatedQuery(request);
  const groupsQuery = useGetVehicleGroupsQuery({ user });

  const refetchTransactions = () => {
    groupsQuery.refetch();
    transactionsQuery.refetch();
  };

  const resetApiState = () => {
    dispatch(transactionsApiTemplateSlice.util.resetApiState());
  };

  return {
    transactions: transactionsQuery.data?.items || [],
    totalTransactions: transactionsQuery.data?.totalItems || 0,
    isLoading:
      transactionsQuery.isLoading ||
      groupsQuery.isLoading ||
      transactionsQuery.isFetching ||
      groupsQuery.isFetching,
    error: transactionsQuery.error || groupsQuery.error,
    refetchTransactions,
    resetApiState,
  };
};

export const useCurrencyOptions = () => {
  const { transactions } = useTransactions();
  return useMemo(() => {
    const currencies = [
      ...new Set(
        transactions?.filter((x) => !!x.currency).map((x) => x.currency!),
      ),
    ].sort((a, b) => a.localeCompare(b));

    if (currencies.length === 0) {
      currencies.push(DEFAULT_CURRENCY);
    }
    return currencies.map((c) => ({ id: c, label: c }));
  }, [transactions]);
};

export const { useGetTransactionsPaginatedQuery } =
  injectedTransactionApiEndpoints;
