import {
  CustomFormattedMessage,
  TranslationsKeys,
} from '../../../../i18n/i18n';

type ChapterNoteProps = {
  messageId: TranslationsKeys;
};

export const ChapterNote = ({ messageId }: ChapterNoteProps) => (
  <div className={'col-xs-12 margin-top-25 text-size-12'}>
    <CustomFormattedMessage id={messageId} />
  </div>
);
