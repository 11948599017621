import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import React, { useEffect } from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  closeSidebar,
  getSidebarTransaction,
  isSidebarOpen,
} from '../../state/transactionsUiSlice';
import { useLocation } from 'react-router-dom';
import { TransactionSummaryTable } from './TransactionSummaryTable';
import { StationDetailsTable } from './StationDetailsTable';
import { TransactionAmountsTable } from './TransactionAmountsTable';
import { SidebarVehicleHeadline } from '../../../../components/sidebar/SidebarVehicleHeadline';
import { TRANSACTIONS_ROUTE } from '../../../../routes/routes';

export const TransactionSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isSidebarOpen);
  const transactionWithVehicle = useAppSelector(getSidebarTransaction);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== TRANSACTIONS_ROUTE) {
      dispatch(closeSidebar());
    }
  }, [location.pathname]);

  const handleClose = () => {
    dispatch(closeSidebar());
  };

  if (!isOpen || !transactionWithVehicle) {
    return <></>;
  }

  const { transaction, vehicleWithGroups } = transactionWithVehicle;

  return (
    <ApplicationLayout.Sidebar
      className={'right'}
      data-testid={'transactions-sidebar'}
    >
      <Sidebar
        title={<CustomFormattedMessage id={'transactions.sidebar.title'} />}
        titleClassName={
          'padding-left-10 text-light text-uppercase text-size-16'
        }
        onClose={handleClose}
        resizable
        position={'right'}
        width={400}
        minWidth={330}
        maxWidth={600}
      >
        <div className={'padding-left-20 padding-right-20'}>
          <SidebarVehicleHeadline vehicleWithGroups={vehicleWithGroups!} />
          <TransactionSummaryTable
            transactionWithVehicle={transactionWithVehicle}
          />
          <StationDetailsTable
            poi={transaction!.poi}
            pumpNumber={transaction?.pumpNumber}
          />
          <TransactionAmountsTable transaction={transaction} />
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};
