import { useLocale } from '../../../../i18n/locale';
import { getImageAssetWithLocale, imageKeys } from './imageAssets';
import { userManualDialogChapters } from '../../../../state/dialogs/chapters';
import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';
import { ChapterCarousel } from '../chapterComponents/ChapterCarousel';

export const ActivatingServicesChapter: React.FC = () => {
  const { locale } = useLocale();

  const activatingCard1 = getImageAssetWithLocale(
    imageKeys.activatingCard1,
    locale,
  );
  const activatingCard2 = getImageAssetWithLocale(
    imageKeys.activatingCard2,
    locale,
  );

  return (
    <div className={'row'}>
      <ChapterHeadline messageId={'userManual.activatingServices.headline'} />
      <ChapterText
        messageId={
          'userManual.activatingServices.activateServiceForVehicle.text'
        }
        referenceToChapter={userManualDialogChapters.addingServiceCards}
      />
      <ChapterCarousel
        images={[{ src: activatingCard1 }, { src: activatingCard2 }]}
      />
      <ChapterText
        messageId={
          'userManual.activatingServices.activateDeactivateService.text'
        }
      />
    </div>
  );
};
