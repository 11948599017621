import React from 'react';
import fuelingLogo from '../../assets/images/partners/fueling-placeholder.png';
import breakdownLogo from '../../assets/images/partners/breakdown-placeholder.png';
import roadSideLogo from '../../assets/images/partners/roadside-placeholder.png';
import mobile24Logo from '../../assets/images/partners/man-mobile24-logo.svg';
import travisLogo from '../../assets/images/partners/travis-logo.png';
import utaLogo from '../../assets/images/partners/uta-logo.png';
import { ServicePartner } from '../../codegen/transactions';
import { TranslationsKeys, useCustomIntl } from '../../i18n/i18n';

type ServicePartnerIconProps = {
  servicePartner: ServicePartner;
  className?: string;
};

const servicePartnersToIcon: { [key in ServicePartner]: string } = {
  MOCK_FUELING_PARTNER: fuelingLogo,
  MOCK_BREAKDOWN_PARTNER: breakdownLogo,
  MOCK_ROADSIDE_PARTNER: roadSideLogo,
  MOBILE_24: mobile24Logo,
  TRAVIS: travisLogo,
  UTA: utaLogo,
};

const servicePartnersToAltText: { [key in ServicePartner]: TranslationsKeys } =
  {
    MOCK_FUELING_PARTNER: 'common.servicePartnerType.mockFueling',
    MOCK_BREAKDOWN_PARTNER: 'common.servicePartnerType.mockBreakdown',
    MOCK_ROADSIDE_PARTNER: 'common.servicePartnerType.mockRoadside',
    MOBILE_24: 'common.servicePartnerType.mobile24',
    TRAVIS: 'common.servicePartnerType.travis',
    UTA: 'common.servicePartnerType.uta',
  };

export const ServicePartnerIcon: React.FC<ServicePartnerIconProps> = ({
  servicePartner,
  className,
}) => {
  const intl = useCustomIntl();

  return (
    <img
      alt={intl.formatMessage({ id: servicePartnersToAltText[servicePartner] })}
      src={servicePartnersToIcon[servicePartner]}
      className={className}
    />
  );
};
