import {
  DialogMode,
  getDialogMode,
  setDialogMode,
} from '../../../../state/onboardingUiSlice';
import { CustomFormattedMessage } from '../../../../../../i18n/i18n';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../../../state/hooks';
import { useVehicleDialogFormContext } from '../../ServiceConfigurationForm';
import { some } from 'lodash';

export const CardTab = () => {
  const dispatch = useAppDispatch();
  const dialogMode = useAppSelector(getDialogMode);

  const fomContext = useVehicleDialogFormContext();

  const isServiceActivationsDirty = some(
    fomContext.formState.dirtyFields.serviceActivations,
    (serviceActivation) => {
      return (
        serviceActivation.enabled ||
        !!serviceActivation.paymentMethodAssignments
      );
    },
  );

  if (isServiceActivationsDirty) {
    return (
      <div
        data-testid={'onboarding-dialog-tab-serviceCards-disabled'}
        className="display-flex flex-column justify-content-center padding-left-10"
      >
        <OverlayTrigger
          placement={'top-start'}
          overlay={
            <Tooltip
              id="tooltip"
              allowOnTouch
            >
              <CustomFormattedMessage
                id={'onboarding.dialog.serviceCards.tab.toolTip'}
              />
            </Tooltip>
          }
        >
          <div className="display-flex align-items-center">
            <div className="text-color-gray">
              <CustomFormattedMessage
                id={'onboarding.dialog.serviceCards.tab'}
              />
            </div>
            <span className="rioglyph rioglyph-question-sign text-color-danger text-size-16 padding-left-5" />
          </div>
        </OverlayTrigger>
      </div>
    );
  }

  return (
    <li
      data-testid={'onboarding-dialog-tab-serviceCards'}
      className={classNames({
        active: dialogMode === DialogMode.SERVICE_CARDS,
      })}
    >
      <div onClick={() => dispatch(setDialogMode(DialogMode.SERVICE_CARDS))}>
        <CustomFormattedMessage id={'onboarding.dialog.serviceCards.tab'} />
      </div>
    </li>
  );
};
