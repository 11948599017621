import { Subscription } from './baseQuery';
import { parse } from 'graphql';

export const onTransactionUpdated: Subscription = {
  definition: parse(`
    subscription OnTransactionUpdated($account_id: String!) {
      onTransactionUpdated(account_id: $account_id) {
          id
      }
    }
  `),
};

export const onPaymentMethodUpdated: Subscription = {
  definition: parse(`
    subscription OnPaymentMethodUpdated($account_id: String!) {
      onPaymentMethodUpdated(account_id: $account_id) {
          id
          state
          vehicle_id
      }
    }
  `),
};
