import AssetTree from '@rio-cloud/rio-uikit/lib/es/AssetTree';
import TreeCategory from '@rio-cloud/rio-uikit/lib/es/TreeCategory';
import React, { useMemo, useState } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import Tree from '@rio-cloud/rio-uikit/Tree';
import { useVehicles } from '../../../../state/vehicles/useVehicles';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { NoDataPlaceholder } from '../../../../components/NoDataPlaceholder';
import { useCustomIntl } from '../../../../i18n/i18n';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  getVehicleGroupsFilter,
  getVehiclesFilter,
  setVehicleGroups,
  setVehicles,
} from '../../../../state/transactions/transactionFilterSlice';

import { TRANSACTIONS_ROUTE } from '../../../../routes/routes';

const TREE_CATEGORY = 'assets';

export const VehicleAssetTree: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const filteredVehicles = useAppSelector(getVehiclesFilter);
  const filteredGroups = useAppSelector(getVehicleGroupsFilter);

  const { isLoading, error, vehicles, groups } = useVehicles();
  const { pathname } = useLocation();
  const intl = useCustomIntl();
  const dispatch = useAppDispatch();

  const handleSelectionChanged = (newSelection: {
    items: string[];
    groups: string[];
  }) => {
    dispatch(setVehicleGroups(newSelection.groups));
    dispatch(setVehicles(newSelection.items));
  };

  const mappedVehicles = useMemo(
    () =>
      vehicles.map((v) => ({
        id: v.id,
        name: v.name,
        type: v.type.toLowerCase(),
        groupIds: v.group_ids,
      })),
    [vehicles],
  );

  const renderTree = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (error) {
      return <NoDataPlaceholder />;
    }

    return isOpen ? (
      <Tree
        items={mappedVehicles}
        groups={groups}
        expandedGroups={groups.map((g) => g.name)}
        searchPlaceholder={intl.formatMessage({
          id: 'transactions.assetTree.searchbarPlaceholder',
        })}
        showEmptyGroups={false}
        onSelectionChange={handleSelectionChanged}
        selectedGroups={filteredGroups}
        selectedItems={filteredVehicles}
      />
    ) : (
      <></>
    );
  };

  return pathname === TRANSACTIONS_ROUTE ? (
    <ApplicationLayout.Sidebar data-testid={'transaction-vehicle-tree'}>
      <AssetTree
        minWidth={300}
        maxWidth={450}
        isOpen={isOpen}
        onToggleTree={() => setIsOpen(!isOpen)}
        currentCategoryId={TREE_CATEGORY}
      >
        <TreeCategory
          hasSelection={
            filteredVehicles.length > 0 || filteredGroups.length > 0
          }
          key={TREE_CATEGORY}
          id={TREE_CATEGORY}
          label={intl.formatMessage({ id: 'common.vehicle.groups' })}
          icon={'rioglyph-truck'}
        >
          {renderTree()}
        </TreeCategory>
      </AssetTree>
    </ApplicationLayout.Sidebar>
  ) : (
    <></>
  );
};
