import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';

export const ChargingChapter = () => {
  return (
    <div className={'row'}>
      <ChapterHeadline messageId={'userManual.charging.headline'} />
      <ChapterText messageId={'userManual.charging.text'} />
    </div>
  );
};
