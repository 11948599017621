import { ServiceRow } from './ServiceRow';
import {
  PaymentMethod,
  ServiceActivation,
} from '../../../../../codegen/vehicles';
import { PaymentMethodDetails } from './PaymentMethodDetails';

type ActiveServiceProps = {
  activeService: ServiceActivation;
  paymentMethods: PaymentMethod[];
};

export const ActiveService = ({
  activeService,
  paymentMethods,
}: ActiveServiceProps) => {
  const assignedPaymentMethods = activeService.paymentMethodAssignments
    .map((pa) => paymentMethods.find((p) => p.id === pa.paymentMethodId))
    .filter((p) => !!p);

  return (
    <>
      <ServiceRow service={activeService.serviceType} />
      {assignedPaymentMethods.map((paymentMethod) => (
        <PaymentMethodDetails
          key={paymentMethod.id}
          paymentMethod={paymentMethod}
        />
      ))}
    </>
  );
};
