import React from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { MonetaryValue } from '../../../../components/MonetaryValue';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';

type OperationalCostPanelFooterProps = {
  isLoading: boolean;
  totalAmount: number;
  currency: string;
  onButtonClick?: () => void;
};

export const OperationalCostPanelFooter: React.FC<
  OperationalCostPanelFooterProps
> = ({ isLoading, totalAmount, currency, onButtonClick }) => {
  if (isLoading || totalAmount <= 0) {
    return <></>;
  }

  return (
    <div className="display-flex align-items-center">
      <span className="rioglyph rioglyph-stats text-color-gray text-size-20 margin-right-5" />
      <span data-testid={'dashboard-operational-costs-panel-footer'}>
        <CustomFormattedMessage
          id={'dashboard.panels.operationalCosts.footer'}
        />
        {': '}
        <b>
          <MonetaryValue
            amount={totalAmount}
            currency={currency}
            countUp
          />
        </b>
      </span>
      <Button
        bsStyle={Button.PRIMARY}
        variant={Button.VARIANT_LINK}
        onClick={onButtonClick}
        data-testid={'dashboard-operational-costs-filter-transaction-button'}
      >
        <span className={'rioglyph rioglyph-th-list text-size-14'} />
      </Button>
    </div>
  );
};
