import {
  enhancedApi,
  useGetVehiclesQuery,
  Vehicle,
} from '../../codegen/vehicles';
import { getUser } from '../../login/loginSlice';
import { VehicleWithGroups } from '../../models';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useGetVehicleGroupsQuery } from '../vehicleGroups/vehicleGroupsSlice';
import moment from 'moment';
import { useMemo } from 'react';
import { getSelectedVehicleId } from '../../pages/onboarding/state/onboardingUiSlice';
import { remove } from 'lodash';

export const useVehicles = () => {
  const user = useAppSelector(getUser);
  const vehiclesQuery = useGetVehiclesQuery();
  const groupsQuery = useGetVehicleGroupsQuery({ user });

  const vehicleItems = vehiclesQuery?.data?.items || [];
  const groups = groupsQuery.data || [];

  const mergeVehicleWithGroups = (vehicle: Vehicle): VehicleWithGroups => {
    const groupsOfVehicle = vehicle.group_ids
      ?.map((vehicleGroupId) => {
        return groups.find((g) => g.id === vehicleGroupId);
      })
      .filter((g) => !!g);
    return {
      ...vehicle,
      groups: groupsOfVehicle,
    };
  };

  const vehicles: VehicleWithGroups[] = useMemo(
    () =>
      vehicleItems.map((vehicle: Vehicle) => {
        return mergeVehicleWithGroups(vehicle);
      }),
    [vehicleItems, groups],
  );

  return {
    vehicles,
    groups,
    isLoading:
      vehiclesQuery.isLoading ||
      groupsQuery.isLoading ||
      vehiclesQuery.isFetching ||
      groupsQuery.isFetching,
    isFetching: vehiclesQuery.isFetching || groupsQuery.isFetching,
    error: vehiclesQuery.error || groupsQuery.error,
  };
};

export const useSelectedVehicle = () => {
  const vehicles = useVehicles();
  const selectedVehicleId = useAppSelector(getSelectedVehicleId);
  return useMemo(
    () => vehicles.vehicles.find((vehicle) => vehicle.id === selectedVehicleId),
    [vehicles, selectedVehicleId],
  );
};

const transformPaymentMethods = (vehicle: Vehicle) => {
  vehicle.paymentMethods = vehicle.paymentMethods.map((paymentMethod) => {
    const convertedExpiry = moment(paymentMethod.expiry, 'MM-YY').format(
      'MM/YYYY',
    );
    return {
      ...paymentMethod,
      expiry: convertedExpiry,
    };
  });
  return vehicle;
};

enhancedApi.enhanceEndpoints({
  endpoints: {
    getVehicles: {
      transformResponse: (response) => {
        response.items.map((vehicle: Vehicle) => {
          return transformPaymentMethods(vehicle);
        });
        return response;
      },
    },
    updateServiceActivations: {
      transformResponse: (response) => {
        return transformPaymentMethods(response);
      },
    },
  },
});

export const useUpdatePaymentMethodsOfCachedVehicles = () => {
  const dispatch = useAppDispatch();

  const removePaymentMethodFromCachedVehicles = (paymentMethodId: string) => {
    dispatch(
      enhancedApi.util.updateQueryData(
        'getVehicles',
        undefined,
        (draftVehicleList) => {
          draftVehicleList.items = draftVehicleList.items.map((vehicle) => {
            removePaymentMethodFromVehicle(vehicle, paymentMethodId);
            removePaymentMethodFromServiceAssignments(vehicle, paymentMethodId);
            return vehicle;
          });
        },
      ),
    );
  };

  const removePaymentMethodFromVehicle = (
    vehicle: Vehicle,
    paymentMethodId: string,
  ) => {
    remove(vehicle.paymentMethods, (pm) => {
      return pm.id === paymentMethodId;
    });
  };

  const removePaymentMethodFromServiceAssignments = (
    vehicle: Vehicle,
    paymentMethodId: string,
  ) => {
    vehicle.serviceActivations = vehicle.serviceActivations.filter(
      (serviceActivation) =>
        !serviceActivation.paymentMethodAssignments.some(
          (paymentMethodAssignment) =>
            paymentMethodAssignment.paymentMethodId === paymentMethodId,
        ),
    );
  };

  return {
    removePaymentMethodFromCachedVehicles,
  };
};
