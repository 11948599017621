import Carousel from '@rio-cloud/rio-uikit/Carousel';
import { ImageLoader } from '../../../ImageLoader';

type ChapterCarouselProps = {
  images: { src: string; alt?: string }[];
};

export const ChapterCarousel = ({ images }: ChapterCarouselProps) => {
  const carouselItems = Array.from(images, (image, index) => (
    <Carousel.Item key={index}>
      <ImageLoader
        src={image.src}
        alt={image.alt}
      />
    </Carousel.Item>
  ));

  return (
    <div className={'col-xs-12 margin-bottom-25'}>
      <Carousel
        controls
        fade={false}
        indicators
        keyboard={false}
        onSelect={() => {}}
        pause="hover"
        slide
        touch
        wrap={false}
        className="offset-indicators shadow-default"
      >
        {carouselItems}
      </Carousel>
    </div>
  );
};
