import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import React, { useEffect } from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { ActiveServicesTable } from './activeServiceTable/ActiveServicesTable';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import {
  closeSidebar,
  DialogMode,
  isSidebarOpen,
  openConfigurationDialog,
} from '../../state/onboardingUiSlice';
import { VehicleHeadline } from '../VehicleHeadline';
import { useLocation } from 'react-router-dom';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import isEmpty from 'lodash/isEmpty';
import { ONBOARDING_ROUTE } from '../../../../routes/routes';
import { useSelectedVehicle } from '../../../../state/vehicles/useVehicles';

export const SIDEBAR_LABEL_COLOR = 'text-color-gray';
export const SIDEBAR_DATA_COLOR = 'text-color-dark';

export const VehicleSidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isSidebarOpen);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== ONBOARDING_ROUTE) {
      dispatch(closeSidebar());
    }
  }, [location.pathname]);

  const selectedVehicle = useSelectedVehicle();

  const handleClose = () => {
    dispatch(closeSidebar());
  };

  const onAssignServices = () => {
    const hasPaymentMethods = !isEmpty(selectedVehicle?.paymentMethods);
    dispatch(
      openConfigurationDialog(
        hasPaymentMethods ? DialogMode.SERVICES : DialogMode.SERVICE_CARDS,
      ),
    );
  };

  if (!isOpen || !selectedVehicle) {
    return <></>;
  }

  return (
    <ApplicationLayout.Sidebar
      className={'right'}
      data-testid={'onboarding-vehicle-sidebar'}
    >
      <Sidebar
        title={<CustomFormattedMessage id={'onboarding.sidebar.title'} />}
        titleClassName={
          'padding-left-10 text-light text-uppercase text-size-16'
        }
        onClose={handleClose}
        resizable
        position={'right'}
        width={400}
        minWidth={330}
        maxWidth={600}
      >
        <div className={'padding-left-20 padding-right-20'}>
          <div className={'display-grid grid-cols-3'}>
            <div className={'grid-colspan-2'}>
              <VehicleHeadline vehicle={selectedVehicle} />
            </div>
            <div className={'grid-colspan-1 text-size-20'}>
              <Button
                bsStyle={'primary'}
                type={'button'}
                onClick={onAssignServices}
                className={'pull-right margin-top-10'}
                data-testid={'onboarding-vehicle-sidebar-edit-button'}
              >
                <span className={'rioglyph rioglyph-pencil'} />
                <CustomFormattedMessage id="onboarding.sidebar.body.editServices" />
              </Button>
            </div>
          </div>
          <ActiveServicesTable />
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};
