import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { useEffect } from 'react';
import { useCustomIntl } from '../../../../../i18n/i18n';
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks';
import {
  closeConfigurationDialog,
  getDialogMode,
  isConfigurationDialogOpen,
} from '../../../state/onboardingUiSlice';
import { DialogFooter } from '../DialogFooter';
import { FormProvider, useForm } from 'react-hook-form';
import {
  createVehicleDialogFormData,
  ServiceConfigurationForm,
} from '../ServiceConfigurationForm';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {
  enhancedApi,
  useUpdateServiceActivationsMutation,
} from '../../../../../codegen/vehicles';
import { RemoveConfirmationDialog } from '../removeConfirmationDialog/RemoveConfirmationDialog';
import { AddNewServiceCardDialog } from '../addNewCardDialog/AddNewServiceCardDialog';
import { useSelectedVehicle } from '../../../../../state/vehicles/useVehicles';
import { ServiceConfigurationDialogBody } from './ServiceConfigurationDialogBody';
import { some } from 'lodash';

export const DIALOG_DATA_COLOR = 'text-color-dark';

export const ServiceConfigurationDialog = () => {
  const dispatch = useAppDispatch();
  const intl = useCustomIntl();
  const dialogMode = useAppSelector(getDialogMode);
  const showConfigurationDialog = useAppSelector(isConfigurationDialogOpen);
  const formUtils = useForm<ServiceConfigurationForm>();

  const [
    updateServiceActivations,
    { isLoading: isUpdateServiceActivationLoading },
  ] = useUpdateServiceActivationsMutation();

  const selectedVehicle = useSelectedVehicle();

  useEffect(() => {
    formUtils.reset(createVehicleDialogFormData(selectedVehicle));
  }, [selectedVehicle]);

  const formData = formUtils.watch();

  const closeDialog = () => {
    dispatch(closeConfigurationDialog());
  };

  const hasPaymentMethodAssignedForEnabledServices = () => {
    return Object.values(formData.serviceActivations).every(
      (serviceActivation) => {
        if (serviceActivation.enabled) {
          return !isEmpty(serviceActivation.paymentMethodAssignments);
        }
        return true;
      },
    );
  };

  const handleSave = async () => {
    await submitServiceActivations();
    closeDialog();
  };

  const submitServiceActivations = async () => {
    map(formData.serviceActivations, (serviceActivation) => {
      if (
        isEmpty(serviceActivation.paymentMethodAssignments) &&
        serviceActivation.enabled
      ) {
        serviceActivation.enabled = false;
      }
      if (!serviceActivation.enabled) {
        serviceActivation.paymentMethodAssignments = [];
      }
    });

    const result = await updateServiceActivations({
      vehicleId: selectedVehicle!.id,
      updateServiceActivationsRequest: {
        serviceActivations: Object.values(formData.serviceActivations),
      },
    });

    if ('error' in result) {
      throw Error(result.error);
    }

    formUtils.reset(formData);
    dispatch(
      enhancedApi.util.updateQueryData(
        'getVehicles',
        undefined,
        (draftVehicles) => {
          draftVehicles.items = draftVehicles.items.map((v) => {
            if (v.id === result.data.id) {
              v = result.data;
            }
            return v;
          });
        },
      ),
    );
  };

  const isServiceActivationsDirty = some(
    formUtils.formState.dirtyFields.serviceActivations,
    (serviceActivation) => {
      return (
        serviceActivation.enabled ||
        !!serviceActivation.paymentMethodAssignments
      );
    },
  );

  if (!selectedVehicle || !showConfigurationDialog) {
    return <></>;
  }

  return (
    <div>
      <FormProvider {...formUtils}>
        <form>
          <Dialog
            data-testid={'service-configuration-dialog'}
            useOverflow
            bsSize={Dialog.SIZE_SM}
            show
            onClose={closeDialog}
            title={intl.formatMessage({ id: 'onboarding.dialog.title' })}
            body={<ServiceConfigurationDialogBody dialogMode={dialogMode} />}
            footer={
              <DialogFooter
                dialogMode={dialogMode}
                showLegalText
                isSaveButtonEnabled={
                  isServiceActivationsDirty &&
                  hasPaymentMethodAssignedForEnabledServices()
                }
                isLoading={isUpdateServiceActivationLoading}
                onClose={closeDialog}
                onSave={handleSave}
              />
            }
          />
          <AddNewServiceCardDialog />
          <RemoveConfirmationDialog />
        </form>
      </FormProvider>
    </div>
  );
};
