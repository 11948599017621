import {
  PaymentPartnerType,
  Transaction,
  TransactionAmount,
  TransactionState,
} from '../../../../codegen/transactions';
import { ServiceType, Vehicle } from '../../../../codegen/vehicles';
import { TransactionWithVehicle, VehicleWithGroups } from '../../../../models';
import { RowData } from '../../../../components/table/Table';

export type TransactionRow = {
  vehicle?: Vehicle;
  service: ServiceType;
  transactionDate?: string;
  billingPartner: PaymentPartnerType;
  serviceCardLastFourDigits: string;
  totalPrice?: TransactionAmount;
  totalPriceConverted?: TransactionAmount;
  status: TransactionState;
};

const mapTransactionAmount = (t: Transaction): TransactionAmount | undefined =>
  t.state === TransactionState.Completed
    ? t.completionAmount
    : t.deliveryAmount;

const mapTransactionToRow = (
  t: Transaction,
  v: VehicleWithGroups | undefined,
): RowData<TransactionRow> => ({
  key: t.id,
  enabled: true,
  vehicle: v,
  service: t.serviceType,
  transactionDate: t.createdAt,
  billingPartner: t.paymentDetails.paymentPartner,
  serviceCardLastFourDigits: t.paymentDetails.lastFourDigits,
  totalPrice: mapTransactionAmount(t),
  totalPriceConverted: t.dashboardAmount,
  status: t.state,
});

export const mapTransactionsToRows = (
  transactions: Transaction[],
  vehicles: VehicleWithGroups[],
  transactionsVehicleMap: Map<string, TransactionWithVehicle>,
): RowData<TransactionRow>[] => {
  return transactions.map((transaction: Transaction) => {
    const vehicleWithGroups = vehicles.find(
      (v) => v.id === transaction.vehicleId,
    );
    transactionsVehicleMap.set(transaction.id, {
      transaction,
      vehicleWithGroups,
    });
    return mapTransactionToRow(transaction, vehicleWithGroups);
  });
};
