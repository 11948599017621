import { createApi } from '@reduxjs/toolkit/query/react';
import { restBaseQuery } from '../../api/rest/baseQuery';
import { appConfiguration } from '../../configuration/appConfiguration';

export const bookingsApiTemplateSlice = createApi({
  reducerPath: 'bookingsApi',
  baseQuery: restBaseQuery(appConfiguration.simplepay.api.url),
  endpoints: () => ({}),
});

export const {
  reducer: bookingsReducer,
  reducerPath: bookingsReducerPath,
  middleware: bookingsMiddleware,
} = bookingsApiTemplateSlice;
