import { useMemo } from 'react';
import { ActiveService } from './ActiveService';
import {
  ServiceActivation,
  ServiceType,
} from '../../../../../codegen/vehicles';
import { NoServices } from '../NoServices';
import { useSelectedVehicle } from '../../../../../state/vehicles/useVehicles';

export const ActiveServicesTable = () => {
  const selectedVehicle = useSelectedVehicle();

  const services = useMemo(() => {
    const activeServices = selectedVehicle?.serviceActivations || [];

    const servicesMap: {
      [key in ServiceType]: ServiceActivation | undefined;
    } = {
      FUELING: undefined,
      BREAKDOWN: undefined,
      PARKING: undefined,
      WASHING: undefined,
      CHARGING: undefined,
      OTHER: undefined,
    };

    for (const service of activeServices) {
      servicesMap[service.serviceType] = service;
    }

    return Object.values(servicesMap).filter((s) => !!s) as ServiceActivation[];
  }, [selectedVehicle?.serviceActivations]);

  if (!selectedVehicle || services.length === 0) {
    return <NoServices />;
  }

  return services.map((service) => (
    <div
      key={service.serviceType}
      className={'padding-bottom-10 padding-top-10'}
      data-testid={`onboarding-vehicle-sidebar-service-${service.serviceType}`}
    >
      <table className="table table-condensed">
        <tbody>
          <ActiveService
            activeService={service}
            paymentMethods={selectedVehicle?.paymentMethods ?? []}
          />
        </tbody>
      </table>
    </div>
  ));
};
