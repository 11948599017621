import { ImageLoader } from '../../../ImageLoader';
import { useLocale } from '../../../../i18n/locale';
import { getImageAssetWithLocale, imageKeys } from './imageAssets';
import { TransactionStateLabel } from '../../../../pages/transactions/components/TransactionStateLabel';
import { TransactionState } from '../../../../codegen/transactions';
import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterSubHeadline } from '../chapterComponents/ChapterSubHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';

export const DisplayOfTransactionsChapter: React.FC = () => {
  const { locale } = useLocale();

  const displayTransactions1 = getImageAssetWithLocale(
    imageKeys.displayTransactions1,
    locale,
  );
  const displayTransactions2 = getImageAssetWithLocale(
    imageKeys.displayTransactions2,
    locale,
  );
  const displayTransactions3 = getImageAssetWithLocale(
    imageKeys.displayTransactions3,
    locale,
  );

  return (
    <div className={'row'}>
      <ChapterHeadline messageId={'userManual.displayTransactions.headline'} />
      <ImageLoader
        className={'col-xs-12 margin-bottom-15'}
        src={displayTransactions1}
      />
      <ChapterText
        messageId={'userManual.displayTransactions.text1'}
        marginBottom={15}
      />
      <ImageLoader
        className={'col-xs-12 margin-bottom-15'}
        src={displayTransactions2}
      />
      <ChapterText
        messageId={'userManual.displayTransactions.text2'}
        marginBottom={15}
      />
      <ChapterSubHeadline
        messageId={'userManual.displayTransactions.status.subHeadline'}
      />
      <ChapterText messageId={'userManual.displayTransactions.status.text'} />

      <div className={'col-xs-12 margin-bottom-10'}>
        <div className={'col-xs-2'}>
          <TransactionStateLabel state={TransactionState.Created} />
        </div>
        <ChapterText
          colSize={10}
          marginBottom={0}
          messageId={
            'userManual.displayTransactions.status.created.description'
          }
        />
      </div>
      <div className={'col-xs-12 margin-bottom-10'}>
        <div className={'col-xs-2'}>
          <TransactionStateLabel state={TransactionState.InProgress} />
        </div>
        <ChapterText
          colSize={10}
          marginBottom={0}
          messageId={
            'userManual.displayTransactions.status.inProgress.description'
          }
        />
      </div>
      <div className={'col-xs-12 margin-bottom-10'}>
        <div className={'col-xs-2'}>
          <TransactionStateLabel state={TransactionState.Delivered} />
        </div>
        <ChapterText
          colSize={10}
          marginBottom={0}
          messageId={
            'userManual.displayTransactions.status.notInvoiced.description'
          }
        />
      </div>
      <div className={'col-xs-12 margin-bottom-10'}>
        <div className={'col-xs-2'}>
          <TransactionStateLabel state={TransactionState.Completed} />
        </div>
        <ChapterText
          colSize={10}
          marginBottom={0}
          messageId={
            'userManual.displayTransactions.status.invoiced.description'
          }
        />
      </div>
      <div className={'col-xs-12 margin-bottom-10'}>
        <div className={'col-xs-2'}>
          <TransactionStateLabel state={TransactionState.Canceled} />
        </div>
        <ChapterText
          colSize={10}
          marginBottom={0}
          messageId={
            'userManual.displayTransactions.status.cancelled.description'
          }
        />
      </div>
      <div className={'col-xs-12 margin-bottom-25'}>
        <div className={'col-xs-2'}>
          <TransactionStateLabel state={TransactionState.Failed} />
        </div>
        <ChapterText
          colSize={10}
          marginBottom={0}
          messageId={'userManual.displayTransactions.status.failed.description'}
        />
      </div>

      <ChapterSubHeadline
        messageId={'userManual.displayTransactions.prices.subHeadline'}
      />
      <ChapterText messageId={'userManual.displayTransactions.prices.text'} />

      <ChapterSubHeadline
        messageId={'userManual.displayTransactions.export.subHeadline'}
      />
      <ImageLoader
        className={'col-xs-12 margin-bottom-15'}
        src={displayTransactions3}
      />
      <ChapterText messageId={'userManual.displayTransactions.export.text'} />
    </div>
  );
};
