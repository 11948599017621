import SplitDialog from '@rio-cloud/rio-uikit/lib/es/SplitDialog';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import React from 'react';
import { CustomFormattedMessage } from '../../../i18n/i18n';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { UserManualDialogMenu } from './UserManualDialogMenu';
import { Chapters } from './chapters/Chapters';
import {
  closeUserManualDialog,
  isUserManualDialogOpen,
} from '../../../state/dialogs/useUserManualDialog';

export const UserManualDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isUserManualDialogOpen);

  const onClose = () => {
    dispatch(closeUserManualDialog());
  };

  return (
    // TODO: workaround for a bug in RIO UIKit dialog (attribute show seems not to change the classname)
    isOpen && (
      <SplitDialog
        data-testid="user-manual-dialog"
        show={isOpen}
        title={<CustomFormattedMessage id={'common.information.moduleName'} />}
        leftContent={<UserManualDialogMenu />}
        rightContent={
          <div data-testid={'user-manual-right-content'}>
            <Chapters />
          </div>
        }
        bsSize={Dialog.SIZE_LG}
        onClose={onClose}
        showCloseButton
      />
    )
  );
};
