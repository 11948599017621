import classNames from 'classnames';
import { CustomFormattedMessage, TranslationsKeys } from '../../../i18n/i18n';
import { UserManualDialogChaptersKeys } from '../../../state/dialogs/chapters';

type ChapterMenuLinkProps = {
  chapter: UserManualDialogChaptersKeys;
  isActive: boolean;
  onClick: () => void;
  title: TranslationsKeys;
};

export const ChapterMenuLink = ({
  chapter,
  isActive,
  title,
  onClick,
}: ChapterMenuLinkProps) => {
  return (
    <span
      className={classNames({ active: isActive })}
      onClick={onClick}
      data-testid={chapter + '-menu-btn'}
      role={'button'}
    >
      <CustomFormattedMessage id={title} />
    </span>
  );
};
