import ImagePreloader from '@rio-cloud/rio-uikit/ImagePreloader';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import classNames from 'classnames';

type ImagePreloaderChildProps = {
  image: { src: string };
};

type ImageLoaderProps = {
  src: string;
  alt?: string;
  className?: string;
};

export const ImageLoader = (props: ImageLoaderProps) => {
  const { src, alt, className } = props;

  const classes = classNames('', className);

  return (
    <ImagePreloader src={src}>
      {({ image }: ImagePreloaderChildProps) => {
        if (image) {
          return (
            <img
              className={`img-responsive ${classes}`}
              src={image.src}
              alt={alt}
            />
          );
        }
        return (
          <div className={`aspect-ratio-resizer ${classes}`}>
            <svg viewBox={'0 0 970 347'} />
            <div>
              <div className={'content-center height-100pct'}>
                <Spinner isInverse={false} />
              </div>
            </div>
          </div>
        );
      }}
    </ImagePreloader>
  );
};
