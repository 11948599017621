import { CustomFormattedMessage } from '../../../../../../i18n/i18n';
import { useAppDispatch } from '../../../../../../state/hooks';
import { useSelectedVehicle } from '../../../../../../state/vehicles/useVehicles';
import { useVehicleDialogFormContext } from '../../ServiceConfigurationForm';
import { appConfiguration } from '../../../../../../configuration/appConfiguration';
import { PaymentPartnerType } from '../../../../../../codegen/vehicles';
import map from 'lodash/map';
import { ServiceCardPanel } from './ServiceCardPanel';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { openAddNewServiceCardDialog } from '../../../../state/onboardingUiSlice';

export const CardTabContent = () => {
  const fomContext = useVehicleDialogFormContext();
  const formData = fomContext.watch();
  const selectedVehicle = useSelectedVehicle();
  const dispatch = useAppDispatch();

  const defaultPaymentPartnerType = appConfiguration
    .supportedPaymentPartnerTypes[0] as PaymentPartnerType;

  return (
    <>
      <div className="text-size-16 text-medium margin-top-20 margin-bottom-20">
        <CustomFormattedMessage id={'onboarding.dialog.serviceCards.message'} />
      </div>
      <>
        <div className="justify-content-center margin-bottom-20">
          {map(formData?.paymentMethods, (paymentMethod) => (
            <ServiceCardPanel
              vehicleId={selectedVehicle!.id}
              paymentMethod={paymentMethod}
            />
          ))}
        </div>
        <div className="display-flex justify-content-center margin-bottom-20">
          <Button
            data-testid={'onboarding-dialog-add-new-service-button'}
            bsStyle={'primary'}
            type={'button'}
            onClick={() =>
              dispatch(
                openAddNewServiceCardDialog({
                  paymentPartnerType: defaultPaymentPartnerType,
                }),
              )
            }
            className={'pull-right margin-top-10'}
          >
            <CustomFormattedMessage id="onboarding.addServiceCard.dialog.title" />
          </Button>
        </div>
      </>
    </>
  );
};
