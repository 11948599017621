import {
  Booking,
  BookingStatus,
  enhancedApi,
  ListBookingsApiArg,
  Status,
  useCancelParkingBookingMutation,
} from '../../codegen/bookings';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getSelectedBooking } from '../../pages/bookings/state/bookingsUiSlice';
import { RootState } from '../store';
import { bookingsApiTemplateSlice } from './bookingsApiTemplateSlice';

type CancelResult = { data: Booking } | { error: any };

export const useCancelSelectedBooking = () => {
  const selectedBooking = useAppSelector(getSelectedBooking);
  const dispatch = useAppDispatch();

  const [cancel, { isLoading, error, status, isSuccess }] =
    useCancelParkingBookingMutation();

  const bookingsApiState = useAppSelector(
    (state: RootState) => state[bookingsApiTemplateSlice.reducerPath],
  );

  const cancelParkingBooking = () => {
    if (!selectedBooking) {
      return;
    }

    const bookingId = selectedBooking.id;

    cancel({
      bookingId,
      cancelParkingLotRequest: {
        status: Status.Canceled,
      },
    }).then((result: CancelResult) => {
      if ('error' in result) {
        return;
      }

      updateCachedBookings();
    });
  };

  const updateCachedBookings = () => {
    const cachedListBookingsArgs: ListBookingsApiArg[] = Object.values(
      bookingsApiState.queries,
    )
      .filter((query) => query?.endpointName === 'listBookings')
      .map((entry) => entry?.originalArgs) as ListBookingsApiArg[];

    for (const args of cachedListBookingsArgs) {
      dispatch(
        enhancedApi.util.updateQueryData(
          'listBookings',
          args,
          (draftBookings) => {
            draftBookings.items.forEach((booking: Booking) => {
              if (booking.id === selectedBooking?.id) {
                booking.status = BookingStatus.Canceled;
              }
            });
          },
        ),
      );
    }
  };

  return {
    cancelParkingBooking,
    isLoading,
    error,
    status,
    isSuccess,
  };
};
