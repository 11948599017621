import { ImageLoader } from '../../../ImageLoader';
import { useLocale } from '../../../../i18n/locale';
import { getImageAssetWithLocale, imageKeys } from './imageAssets';
import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';

export const DashboardFunctionsChapter: React.FC = () => {
  const { locale } = useLocale();

  const dashboardOverview1 = getImageAssetWithLocale(
    imageKeys.dashboardOverview1,
    locale,
  );
  const dashboardOverviewCard1 = getImageAssetWithLocale(
    imageKeys.dashboardOverviewCard1,
    locale,
  );
  const dashboardOverviewCard2 = getImageAssetWithLocale(
    imageKeys.dashboardOverviewCard2,
    locale,
  );
  const dashboardOverviewCard3 = getImageAssetWithLocale(
    imageKeys.dashboardOverviewCard3,
    locale,
  );
  const dashboardOverviewCard4 = getImageAssetWithLocale(
    imageKeys.dashboardOverviewCard4,
    locale,
  );
  const dashboardOverviewCard5 = getImageAssetWithLocale(
    imageKeys.dashboardOverviewCard5,
    locale,
  );
  const dashboardOverviewCard6 = getImageAssetWithLocale(
    imageKeys.dashboardOverviewCard6,
    locale,
  );
  const dashboardOverviewCard7 = getImageAssetWithLocale(
    imageKeys.dashboardOverviewCard7,
    locale,
  );

  return (
    <div>
      <ChapterHeadline messageId={'userManual.dashboardFunctions.headline'} />
      <ImageLoader
        className={'col-xs-12 margin-bottom-15'}
        src={dashboardOverview1}
      />
      <ChapterText messageId={'userManual.dashboardFunctions.text'} />
      <div className={'row margin-15'}>
        <div className={'col-xs-12 display-flex align-items-center margin-10'}>
          <ImageLoader
            className={
              'col-xs-5 border-style-solid border-width-1 border-color-light'
            }
            src={dashboardOverviewCard1}
          />
          <ChapterText
            colSize={6}
            className={'margin-left-25'}
            messageId={
              'userManual.dashboardFunctions.vehiclesWithTransaction.text'
            }
          />
        </div>
        <div className={'col-xs-12 display-flex align-items-center margin-10'}>
          <ImageLoader
            src={dashboardOverviewCard2}
            className={
              'col-xs-5 border-style-solid border-width-1 border-color-light'
            }
          />
          <ChapterText
            colSize={6}
            className={'margin-left-25'}
            messageId={'userManual.dashboardFunctions.totalSumAndAverage.text'}
          />
        </div>
        <div className={'col-xs-12 display-flex align-items-center margin-10'}>
          <ImageLoader
            src={dashboardOverviewCard3}
            className={
              'col-xs-5 border-style-solid border-width-1 border-color-light'
            }
          />
          <ChapterText
            colSize={6}
            className={'margin-left-25'}
            messageId={'userManual.dashboardFunctions.costPerService.text'}
          />
        </div>
        <div className={'col-xs-12 display-flex align-items-center margin-10'}>
          <ImageLoader
            src={dashboardOverviewCard4}
            className={
              'col-xs-5 border-style-solid border-width-1 border-color-light'
            }
          />
          <ChapterText
            colSize={6}
            className={'margin-left-25'}
            messageId={
              'userManual.dashboardFunctions.vehicleCostSortedAsc.text'
            }
          />
        </div>
        <div className={'col-xs-12 display-flex align-items-center margin-10'}>
          <ImageLoader
            src={dashboardOverviewCard5}
            className={
              'col-xs-5 border-style-solid border-width-1 border-color-light'
            }
          />
          <ChapterText
            colSize={6}
            className={'margin-left-25'}
            messageId={
              'userManual.dashboardFunctions.vehicleCostSortedDesc.text'
            }
          />
        </div>
        <div className={'col-xs-12 display-flex align-items-center margin-10'}>
          <ImageLoader
            src={dashboardOverviewCard6}
            className={
              'col-xs-5 border-style-solid border-width-1 border-color-light'
            }
          />
          <ChapterText
            colSize={6}
            className={'margin-left-25'}
            messageId={
              'userManual.dashboardFunctions.transactionsPerService.text'
            }
          />
        </div>
        <div className={'col-xs-12 display-flex align-items-center margin-10'}>
          <ImageLoader
            src={dashboardOverviewCard7}
            className={
              'col-xs-5 border-style-solid border-width-1 border-color-light'
            }
          />
          <ChapterText
            colSize={6}
            messageId={'userManual.dashboardFunctions.costsOverTime.text'}
            className={'margin-left-25'}
          />
        </div>
      </div>
    </div>
  );
};
