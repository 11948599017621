import React from 'react';
import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import { InfoPopover } from './InfoPopover';
import { CustomFormattedMessage } from '../../i18n/i18n';

export const SupportMenu: React.FC = () => {
  const title = (
    <div>
      <CustomFormattedMessage id="common.information.moduleName" />
    </div>
  );

  return (
    <ActionBarItem>
      <ActionBarItem.Icon>
        <span
          className="icon rioglyph rioglyph-info-sign"
          data-testid={'base-header-support-menu-icon'}
        />
      </ActionBarItem.Icon>
      <ActionBarItem.Popover title={title}>
        <InfoPopover />
      </ActionBarItem.Popover>
    </ActionBarItem>
  );
};
