import { useFormContext } from 'react-hook-form';
import {
  PaymentMethod,
  PaymentMethodType,
  PaymentPartnerType,
  ServiceActivationUpdate,
  ServiceType,
  Vehicle,
} from '../../../../codegen/vehicles';
import { remove } from 'lodash';
import { useAppSelector } from '../../../../state/hooks';
import { getPaymentPartnerType } from '../../state/onboardingUiSlice';
import { appConfiguration } from '../../../../configuration/appConfiguration';

export type ServiceConfigurationForm = {
  serviceActivations: {
    [serviceType: string]: ServiceActivationUpdate;
  };
  paymentMethods: PaymentMethod[];
  newServiceCard: {
    type: PaymentMethodType;
    paymentPartner: PaymentPartnerType;
    cardNumber: string;
    expiryDate: string;
  };
};

export const createVehicleDialogFormData = (vehicle?: Vehicle) => {
  const formData: ServiceConfigurationForm = {
    serviceActivations: {},
    paymentMethods: [],
    newServiceCard: {
      type: PaymentMethodType.FuelCard,
      paymentPartner: PaymentPartnerType.MockFuelingPayment,
      cardNumber: '',
      expiryDate: '',
    },
  };
  appConfiguration.supportedServiceTypes.forEach((serviceType: string) => {
    if (!formData.serviceActivations[serviceType]) {
      const serviceActivation = vehicle?.serviceActivations?.find(
        (s) => s.serviceType === (serviceType as ServiceType),
      );

      if (serviceActivation) {
        formData.serviceActivations[serviceType] = {
          ...serviceActivation,
          enabled: true,
        };
      } else {
        formData.serviceActivations[serviceType] = {
          enabled: false,
          serviceType: serviceType as ServiceType,
          paymentMethodAssignments: [],
        };
      }
    }
  });
  formData.paymentMethods = vehicle?.paymentMethods || [];
  return formData;
};

export const useVehicleDialogFormContext = () => {
  const form = useFormContext<ServiceConfigurationForm>();
  const selectedPaymentPartnerType = useAppSelector(getPaymentPartnerType);
  if (
    selectedPaymentPartnerType !==
    form.getValues()?.newServiceCard?.paymentPartner
  ) {
    form.setValue('newServiceCard.paymentPartner', selectedPaymentPartnerType);
  }

  const isServiceEnabled = (serviceType: ServiceType) => {
    return form.watch(`serviceActivations.${serviceType}.enabled`);
  };

  const getServiceActivations = () => {
    return form.getValues('serviceActivations');
  };

  const removePaymentMethodFromServices = (
    serviceTypes: ServiceType[],
    paymentMethodId: string,
  ) => {
    serviceTypes.forEach((serviceType) => {
      const serviceActivation = form.getValues(
        `serviceActivations.${serviceType}`,
      );
      form.setValue(`serviceActivations.${serviceType}`, {
        ...serviceActivation,
        paymentMethodAssignments: [],
      });

      form.setValue(`serviceActivations.${serviceType}.enabled`, false);
    });

    removePaymentMethod(paymentMethodId);
  };

  const removePaymentMethod = (paymentMethodId: string) => {
    const paymentMethods = form.getValues('paymentMethods');
    remove(paymentMethods, { id: paymentMethodId });
    form.setValue('paymentMethods', paymentMethods);
  };

  const getNewServiceCard = () => {
    return form.getValues('newServiceCard');
  };

  const getPaymentMethods = () => {
    return form.getValues('paymentMethods');
  };

  const setPaymentMethods = (paymentMethods: PaymentMethod[]) => {
    return form.setValue('paymentMethods', paymentMethods);
  };

  return {
    ...form,
    getServiceActivations,
    getNewServiceCard,
    isServiceEnabled,
    removePaymentMethod,
    removePaymentMethodFromServices,
    getPaymentMethods,
    setPaymentMethods,
  };
};
