import { VehicleIcon } from '../../../../components/icons/VehicleIcon';
import { MonetaryValue } from '../../../../components/MonetaryValue';
import { Top5CostsPanelType } from './Top5CostsPanel';
import { VehicleTotal } from './useTop5CostsPanel';

type Props = {
  type: Top5CostsPanelType;
  top5Vehicles: VehicleTotal[];
  currency: string;
};

export const Top5Entries = ({ type, top5Vehicles, currency }: Props) => {
  const paddingBottom: string = (() => {
    if (top5Vehicles.length < 4) {
      return 'padding-bottom-20';
    }
    if (top5Vehicles.length < 5) {
      return 'padding-bottom-10';
    }
    return '';
  })();

  return (
    <>
      {top5Vehicles.map((vehicleTotal: VehicleTotal, index: number) => (
        <div
          key={vehicleTotal.vehicle.id}
          data-testid={`dashboard-top5-costs-${type}-vehicle-${index}`}
          className={
            'height-30 display-flex flex-row justify-content-between padding-left-20 padding-right-20 ' +
            `margin-bottom-5 text-size-16 ${paddingBottom}`
          }
          style={{ boxSizing: 'content-box' }}
        >
          <div className="text-color-dark display-flex flex-row justify-content-center">
            <span
              className={
                'height-20 text-size-14 pull-left text-right text-medium text-color-white rounded-circle ' +
                'margin-right-10 margin-top-3 width-20 aspect-ratio-1 display-flex justify-content-center ' +
                (type === Top5CostsPanelType.LOWEST
                  ? 'bg-primary'
                  : 'bg-warning')
              }
            >
              {index + 1}
            </span>
            {vehicleTotal.vehicle && (
              <>
                <VehicleIcon
                  type={vehicleTotal.vehicle.type}
                  className="padding-right-5 text-size-h3"
                />
                <span
                  className={
                    'height-25 max-width-150 white-space-nowrap overflow-hidden ' +
                    'margin-top-1 padding-top-1'
                  }
                >
                  {vehicleTotal.vehicle.name}
                </span>
              </>
            )}
          </div>
          <div
            className={
              'margin-top-1 padding-top-1 ' +
              (type === Top5CostsPanelType.LOWEST
                ? 'text-color-success'
                : 'text-color-danger')
            }
          >
            <MonetaryValue
              amount={vehicleTotal.totalAmount}
              currency={currency}
            />
          </div>
        </div>
      ))}
    </>
  );
};
