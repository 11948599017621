import { CustomFormattedMessage } from '../../../../../../i18n/i18n';
import map from 'lodash/map';
import { ServiceExpander } from './ServiceExpander';
import { useVehicleDialogFormContext } from '../../ServiceConfigurationForm';

export const ServiceTabContent = () => {
  const fomContext = useVehicleDialogFormContext();
  const formData = fomContext.watch();

  return (
    <>
      <div className="text-size-16 text-medium margin-top-20 margin-bottom-20">
        <CustomFormattedMessage id={'onboarding.dialog.services.message'} />
      </div>
      {map(formData.serviceActivations, (s) => (
        <ServiceExpander
          serviceType={s.serviceType}
          key={s.serviceType}
        />
      ))}
    </>
  );
};
