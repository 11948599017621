import { CustomFormattedMessage, TranslationsKeys } from '../i18n/i18n';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';

export const GRAPHQL_ERROR_TITLE_KEY: TranslationsKeys =
  'base.body.notificationBanner.graphqlFailureTitle';
export const GRAPHQL_ERROR_MESSAGE_KEY: TranslationsKeys =
  'base.body.notificationBanner.graphqlFailureMessage';

export const GRAPHQL_SUBSCRIPTION_ERROR_TITLE_KEY: TranslationsKeys =
  'base.body.notificationBanner.graphqlSubscriptionFailureTitle';
export const GRAPHQL_SUBSCRIPTION_ERROR_MESSAGE_KEY: TranslationsKeys =
  'base.body.notificationBanner.graphqlSubscriptionFailureMessage';

export const notifyError = (
  title: TranslationsKeys,
  message: TranslationsKeys,
) => {
  Notification.error(<CustomFormattedMessage id={message} />, {
    title: <CustomFormattedMessage id={title} />,
  });
};
