import classNames from 'classnames';
import { ImageLoader } from '../../../ImageLoader';
import { getImageAssetWithLocale, imageKeys } from '../chapters/imageAssets';

type MobileAppInfoProps = {
  className?: string;
  locale?: string;
};

export const MobileAppInfo = ({
  className,
  locale = 'en-GB',
}: MobileAppInfoProps) => {
  const manAppIcon = getImageAssetWithLocale(imageKeys.manAppIcon, locale);
  const appleQr = getImageAssetWithLocale(imageKeys.manAppleStoreQr, locale);
  const playQr = getImageAssetWithLocale(imageKeys.manPlayStoreQr, locale);
  const playStore = getImageAssetWithLocale(imageKeys.playStore, locale);
  const appStore = getImageAssetWithLocale(imageKeys.appStore, locale);

  return (
    <div className={classNames('col-xs-12', className)}>
      <div className={'col-xs-4 padding-50'}>
        <ImageLoader src={manAppIcon} />
      </div>
      <div className={'col-xs-4 padding-50'}>
        <ImageLoader src={appleQr} />
        <ImageLoader src={appStore} />
      </div>
      <div className={'col-xs-4 padding-50'}>
        <ImageLoader src={playQr} />
        <ImageLoader src={playStore} />
      </div>
    </div>
  );
};
