import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import React from 'react';
import { CustomFormattedMessage } from '../../../../i18n/i18n';
import { DialogMode } from '../../state/onboardingUiSlice';
import classNames from 'classnames';

type DialogFooterProps = {
  onClose: () => void;
  onSave: () => void;
  dialogMode: DialogMode;
  isSaveButtonEnabled: boolean;
  showLegalText: boolean;
  isLoading: boolean;
};

export const DialogFooter: React.FC<DialogFooterProps> = ({
  isSaveButtonEnabled,
  showLegalText,
  dialogMode,
  isLoading,
  onClose,
  onSave,
}) => {
  if (dialogMode === DialogMode.SERVICES) {
    return (
      <>
        {showLegalText && (
          <div
            data-testid={'transactions-sidebar-amounts'}
            className="margin-bottom-20"
          >
            <CustomFormattedMessage id={'onboarding.dialog.services.legal'} />
          </div>
        )}
        <Button
          bsStyle={'default'}
          type={'button'}
          className={'margin-right-10'}
          onClick={onClose}
          disabled={isLoading}
        >
          <CustomFormattedMessage id={'common.action.cancel'} />
        </Button>
        <Button
          bsStyle={'primary'}
          type={'submit'}
          className={classNames({
            'btn-loading': isLoading,
          })}
          onClick={onSave}
          disabled={!isSaveButtonEnabled || isLoading}
        >
          <CustomFormattedMessage id={'common.action.save'} />
        </Button>
      </>
    );
  } else {
    return (
      <Button
        bsStyle={'default'}
        type={'button'}
        className={'margin-right-10'}
        onClick={onClose}
        disabled={isLoading}
      >
        <CustomFormattedMessage id={'common.action.close'} />
      </Button>
    );
  }
};
