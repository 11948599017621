import classNames from 'classnames';
import {
  CustomFormattedMessage,
  TranslationsKeys,
} from '../../../../i18n/i18n';
import { openUserManualDialog } from '../../../../state/dialogs/useUserManualDialog';
import { useAppDispatch } from '../../../../state/hooks';
import { UserManualDialogChaptersKeys } from '../../../../state/dialogs/chapters';

type ChapterTextProps = {
  messageId: TranslationsKeys;
  className?: string;
  colSize?: number;
  marginBottom?: number;
  referenceToChapter?: UserManualDialogChaptersKeys;
};

export const ChapterText = ({
  messageId,
  className,
  colSize = 12,
  marginBottom = 25,
  referenceToChapter,
}: ChapterTextProps) => {
  const dispatch = useAppDispatch();

  return (
    <div
      className={classNames(
        `col-xs-${colSize} margin-bottom-${marginBottom}`,
        className,
      )}
    >
      <CustomFormattedMessage
        id={messageId}
        values={{
          br: <br />,
          i: (parts: string) => <i>{parts}</i>,
          link: (parts: string) => (
            <a
              className={'text-decoration-underline'}
              onClick={() =>
                dispatch(openUserManualDialog(referenceToChapter!))
              }
            >
              {parts}
            </a>
          ),
        }}
      />
    </div>
  );
};
