import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

type DialogState = {
  open: boolean;
};

const initialState: DialogState = {
  open: false,
};

export const supportDialogSlice = createSlice({
  name: 'supportDialogUi',
  initialState,
  reducers: {
    openSupportDialog: (state) => {
      state.open = true;
    },
    closeSupportDialog: (state) => {
      state.open = false;
    },
  },
});

export const { openSupportDialog, closeSupportDialog } =
  supportDialogSlice.actions;

export const isSupportDialogOpen = (state: RootState) =>
  state.supportDialogState.open;
