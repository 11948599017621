import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';

export const OtherOperatingCostsChapter = () => {
  return (
    <div className={'row'}>
      <ChapterHeadline messageId={'userManual.otherOperatingCosts.headline'} />
      <ChapterText messageId={'userManual.otherOperatingCosts.text'} />
    </div>
  );
};
