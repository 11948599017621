import { UserManualDialogChaptersKeys } from '../../../../state/dialogs/chapters';
import { getOpenChapter } from '../../../../state/dialogs/useUserManualDialog';
import { useAppSelector } from '../../../../state/hooks';
import { RepairMaintenanceChapter } from './RepairMaintenanceChapter';
import { WashingChapter } from './WashingChapter';
import { WelcomeChapter } from './WelcomeChapter';

import { ActivatingServicesChapter } from './ActivatingServicesChapter';
import { AddingServiceCardsChapter } from './AddingServiceCardsChapter';
import { VehicleOverviewChapter } from './VehicleOverviewChapter';
import { OtherOperatingCostsChapter } from './OtherOperatingCostsChapter';
import { DashboardFunctionsChapter } from './DashboardFunctionsChapter';
import { DisplayOfTransactionsChapter } from './DisplayOfTransactionsChapter';
import { BookingsOverviewChapter } from './BookingsOverviewChapter';
import { MobileFuelingChapter } from './MobileFuelingChapter';
import { ParkingChapter } from './ParkingChapter';
import { ReactNode } from 'react';
import { ChargingChapter } from './ChargingChapter';

const chapters: { [key in UserManualDialogChaptersKeys]: ReactNode } = {
  welcome: <WelcomeChapter />,
  vehicleOverview: <VehicleOverviewChapter />,
  addingServiceCards: <AddingServiceCardsChapter />,
  activatingServices: <ActivatingServicesChapter />,
  dashboardFunctions: <DashboardFunctionsChapter />,
  displayTransactions: <DisplayOfTransactionsChapter />,
  bookingOverview: <BookingsOverviewChapter />,
  mobileFueling: <MobileFuelingChapter />,
  parking: <ParkingChapter />,
  repairMaintenance: <RepairMaintenanceChapter />,
  washing: <WashingChapter />,
  charging: <ChargingChapter />,
  otherOperatingCosts: <OtherOperatingCostsChapter />,
};

export const Chapters = () => {
  const currentChapter = useAppSelector(getOpenChapter);

  if (currentChapter === undefined) {
    return <></>;
  }

  return chapters[currentChapter];
};
