import { ImageLoader } from '../../../ImageLoader';
import { useLocale } from '../../../../i18n/locale';
import { getImageAssetWithLocale, imageKeys } from './imageAssets';
import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';
import { ChapterCarousel } from '../chapterComponents/ChapterCarousel';
import { ChapterSubHeadline } from '../chapterComponents/ChapterSubHeadline';
import { ChapterBulletPoint } from '../chapterComponents/ChapterBulletPoint';
import { MobileAppInfo } from '../chapterComponents/MobileAppInfo';

export const ParkingChapter: React.FC = () => {
  const { locale } = useLocale();

  const parking1 = getImageAssetWithLocale(imageKeys.parking1, locale);
  const parking2 = getImageAssetWithLocale(imageKeys.parking2, locale);
  const parking3 = getImageAssetWithLocale(imageKeys.parking3, locale);
  const parking4 = getImageAssetWithLocale(imageKeys.parking4, locale);
  const parkingApp1 = getImageAssetWithLocale(imageKeys.parkingApp1, locale);
  const parkingApp2 = getImageAssetWithLocale(imageKeys.parkingApp2, locale);
  const parkingApp3 = getImageAssetWithLocale(imageKeys.parkingApp3, locale);
  const parkingApp4 = getImageAssetWithLocale(imageKeys.parkingApp4, locale);

  return (
    <div className={'row'}>
      <ChapterHeadline
        messageId={'userManual.parking.activationUse.headline'}
      />
      <ChapterText messageId={'userManual.parking.activationUse.text'} />
      <ChapterSubHeadline
        messageId={'userManual.parking.fleetMonitor.subHeadline'}
      />
      <ChapterText messageId={'userManual.parking.fleetMonitor.text'} />
      <ChapterCarousel
        images={[
          { src: parking1 },
          { src: parking2 },
          { src: parking3 },
          { src: parking4 },
        ]}
      />
      <ChapterSubHeadline
        messageId={'userManual.parking.driverApp.subHeadline'}
      />
      <ChapterText messageId={'userManual.parking.driverApp.text'} />
      <ul className={'col-xs-12 padding-left-25 margin-bottom-25'}>
        <ChapterBulletPoint
          messageId={'userManual.parking.driverApp.bulletPoint1'}
        />
        <ChapterBulletPoint
          messageId={'userManual.parking.driverApp.bulletPoint2'}
        />
        <ChapterBulletPoint
          messageId={'userManual.parking.driverApp.bulletPoint3'}
        />
      </ul>
      <div className={'col-xs-12'}>
        <div className={'col-xs-3 margin-bottom-15 '}>
          <ImageLoader src={parkingApp1} />
        </div>
        <div className={'col-xs-3 margin-bottom-15 '}>
          <ImageLoader src={parkingApp2} />
        </div>
        <div className={'col-xs-3 margin-bottom-15 '}>
          <ImageLoader src={parkingApp3} />
        </div>
        <div className={'col-xs-3 margin-bottom-15 '}>
          <ImageLoader src={parkingApp4} />
        </div>
      </div>
      <MobileAppInfo className={'padding-left-20 padding-right-20'} />
    </div>
  );
};
