import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import React, { useEffect } from 'react';
import { AppRoutes } from './routes/AppRoutes';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE, getMessagesForLocale } from './i18n/i18n';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import { VehicleSidebar } from './pages/onboarding/components/sidebar/VehicleSidebar';
import { Header } from './components/header/Header';
import { useVehicles } from './state/vehicles/useVehicles';
import { getUser } from './login/loginSlice';
import { useAppSelector } from './state/hooks';
import { TransactionSidebar } from './pages/transactions/components/sidebar/TransactionSidebar';
import { VehicleAssetTree } from './pages/transactions/components/assetTree/VehicleAssetTree';
import { SupportDialog } from './components/dialog/supportDialog/SupportDialog';
import { UserManualDialog } from './components/dialog/userManualDialog/UserManualDialog';
import { EVENT_USER_LANGUAGE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { useLocale, UserMenuLanguageChangedEvent } from './i18n/locale';
import {
  graphqlBaseSubscription,
  GraphQlOperation,
  Subscription,
} from './api/graphql/baseQuery';
import {
  onPaymentMethodUpdated,
  onTransactionUpdated,
} from './api/graphql/subscriptions';
import {
  PaymentMethodEvent,
  SubscriptionEvent,
  TransactionEvent,
} from './api/graphql/realtimeDtos';
import { useTransactionUpdateState } from './state/transactions/transactionUpdateSlice';
import { BookingSidebar } from './pages/bookings/components/sidebar/BookingSidebar';
import { usePaymentMethodUpdateNotification } from './components/usePaymentMethodUpdateNotification';
import { usePaymentMethodUpdateState } from './state/vehicles/paymentMethodUpdateSlice';

export const App: React.FC = () => {
  const { locale, setLocale } = useLocale();
  const messages = getMessagesForLocale(locale);
  const user = useAppSelector(getUser);

  useVehicles();
  usePaymentMethodUpdateNotification();

  const { setHasTransactionUpdate } = useTransactionUpdateState();
  const { setPaymentMethodUpdate } = usePaymentMethodUpdateState();

  const handleLanguageChanged = (event: Event) => {
    const language = (event as UserMenuLanguageChangedEvent).detail?.language;
    setLocale(language);
  };

  const handleTransactionUpdate = (
    event: SubscriptionEvent<TransactionEvent>,
  ) => {
    setHasTransactionUpdate(true);
  };

  const handlePaymentMethodUpdate = (
    event: SubscriptionEvent<PaymentMethodEvent>,
  ) => {
    if (!event?.data?.onPaymentMethodUpdated) {
      return;
    }
    const { id, vehicle_id, state } = event.data.onPaymentMethodUpdated;
    setPaymentMethodUpdate({
      vehicleId: vehicle_id,
      paymentMethodId: id,
      paymentMethodState: state,
    });
  };

  const subscribeToTransactionUpdates = () => {
    const subscribeTransactionsRequest: GraphQlOperation<Subscription> = {
      user,
      request: onTransactionUpdated,
      requestParams: { account_id: user.accountId },
    };
    graphqlBaseSubscription(
      subscribeTransactionsRequest,
      handleTransactionUpdate,
    );
  };

  const subscribeToPaymentMethodUpdates = () => {
    const subscribePaymentMethodsRequest: GraphQlOperation<Subscription> = {
      user,
      request: onPaymentMethodUpdated,
      requestParams: { account_id: user.accountId },
    };
    graphqlBaseSubscription(
      subscribePaymentMethodsRequest,
      handlePaymentMethodUpdate,
    );
  };

  useEffect(() => {
    subscribeToTransactionUpdates();
    subscribeToPaymentMethodUpdates();

    setLocale(user.locale);
    document.addEventListener(
      EVENT_USER_LANGUAGE_CHANGED,
      handleLanguageChanged,
    );
  }, []);

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      locale={locale}
      messages={messages}
    >
      <ApplicationLayout>
        <ApplicationLayout.Header>
          <Header />
        </ApplicationLayout.Header>
        <VehicleSidebar />
        <VehicleAssetTree />
        <TransactionSidebar />
        <BookingSidebar />
        <ApplicationLayout.Body>
          <SupportDialog />
          <UserManualDialog />
          <NotificationsContainer />
          <AppRoutes />
        </ApplicationLayout.Body>
      </ApplicationLayout>
    </IntlProvider>
  );
};
