import { CustomFormattedMessage } from '../../../../../i18n/i18n';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import classNames from 'classnames';

type RemoveConfirmationDialogFooterProps = {
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const RemoveConfirmationDialogFooter = ({
  isLoading,
  onClose,
  onSubmit,
}: RemoveConfirmationDialogFooterProps) => {
  return (
    <>
      <Button
        data-testid={'onboarding-dialog-body-cancelRemoveServiceCardButton'}
        bsStyle={'default'}
        type={'button'}
        className={'margin-right-10'}
        onClick={onClose}
        disabled={isLoading}
      >
        <CustomFormattedMessage
          id={'onboarding.dialog.removeCardConfirmation.no'}
        />
      </Button>
      <Button
        data-testid={'onboarding-dialog-body-confirmRemoveServiceCardButton'}
        bsStyle={'primary'}
        type={'submit'}
        className={classNames({ 'btn-loading': isLoading })}
        onClick={onSubmit}
        disabled={isLoading}
      >
        <CustomFormattedMessage
          id={'onboarding.dialog.removeCardConfirmation.yes'}
        />
      </Button>
    </>
  );
};
