import { appConfiguration } from '../configuration/appConfiguration';
import { UserManager } from 'oidc-client-ts';

const BASE_SETTINGS = {
  authority: appConfiguration.login.authority,
  client_id: appConfiguration.login.clientId,
  response_type: 'code',
  scope: appConfiguration.login.requiredScopes,
  redirect_uri: appConfiguration.login.redirectUri,
  silent_redirect_uri: appConfiguration.login.redirectUri,
  includeIdTokenInSilentRenew: false,
  staleStateAge: 600,
  automaticSilentRenew: true,
  loadUserInfo: false,
};

export const createRioUserManager = () => {
  return new UserManager(BASE_SETTINGS);
};
