import {
  CustomFormattedMessage,
  TranslationsKeys,
} from '../../../../i18n/i18n';

type ChapterSubHeadlineProps = {
  messageId: TranslationsKeys;
};

export const ChapterSubHeadline = ({ messageId }: ChapterSubHeadlineProps) => (
  <div className={'col-xs-12 text-size-h4 margin-bottom-15'}>
    <CustomFormattedMessage id={messageId} />
  </div>
);
