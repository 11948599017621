import { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { RowColoring, TableColumn, TableRow } from './TableRow';
import { TableHeader, TableSorting } from './TableHeader';
import { NoDataPlaceholder } from '../NoDataPlaceholder';

type RowMeta = {
  key: string;
  enabled: boolean;
  coloring?: RowColoring;
};
export type RowData<T> = T & RowMeta;

type TableProps<T> = {
  columns: TableColumn<T>[];
  rowData: RowData<T>[];
  onRowClicked: (key: string) => void;
  sorting?: TableSorting<T>;
  isLoading?: boolean;
  highlightedRow?: string;
  bottomOffset?: number;
};

export const Table = <T extends object>({
  columns,
  rowData,
  onRowClicked,
  sorting,
  isLoading = false,
  highlightedRow,
  bottomOffset,
}: TableProps<T>): ReactElement | null => {
  // Calculate height from top of component to bottom of page
  const [componentHeight, setComponentHeight] = useState<string | number>(
    'auto',
  );
  useEffect(() => {
    const calculateHeight = () => {
      const element = document.getElementById('table-container');
      if (element && bottomOffset) {
        const rect = element.getBoundingClientRect();
        const topOffset = rect.top;
        const windowHeight = window.innerHeight;
        const height = windowHeight - topOffset - bottomOffset;
        setComponentHeight(height);
      }
    };
    calculateHeight();
    window.addEventListener('resize', calculateHeight);
    return () => window.removeEventListener('resize', calculateHeight);
  }, [bottomOffset]);

  const isRowHighlighted = (key: string) => {
    return highlightedRow === key;
  };

  const renderRow = (key: string, row: RowData<T>) => {
    return (
      <TableRow
        isLoading={isLoading}
        key={key}
        columns={columns}
        model={row}
        onClick={() => onRowClicked(key)}
        highlighted={isRowHighlighted(key)}
        disabled={!row.enabled}
        coloring={row.coloring}
      />
    );
  };

  if (!isLoading && rowData.length === 0) {
    return <NoDataPlaceholder className={'max-width-100pct height-100pct'} />;
  }

  return (
    <div
      id="table-container"
      style={{
        height: componentHeight,
      }}
    >
      <table
        className={classNames(
          'table',
          'table-column-overflow-hidden',
          'table-bordered',
          'table-sticky-in-container',
          'table-head-filled',
          'table-hover',
        )}
      >
        <TableHeader
          columns={columns}
          sorting={sorting}
        />
        <tbody>
          {isLoading
            ? renderRow('contentLoader', { enabled: true } as RowData<T>)
            : rowData.map((row) => {
                const key = row.key;
                return renderRow(key, row);
              })}
        </tbody>
      </table>
    </div>
  );
};
