import moment from 'moment/moment';
import { useCustomIntl, yearMonthFormat } from '../../../../i18n/i18n';
import { ChartData } from './CostsPerServiceOverTimeChart';
import { ServiceType, Transaction } from '../../../../codegen/transactions';

const FORMAT_MONTH_PATTERN = 'YYYY-MM';

export type CostsPerService = Record<ServiceType, number>;
type CostsPerServiceAndVehicleIds = {
  costsPerService: CostsPerService;
  vehicleIds: Set<string>;
};
type CostsPerServiceAndVehicleIdsPerMonth = Record<
  string,
  CostsPerServiceAndVehicleIds
>;

const buildEmptyCostsPerServiceAndVehicleIds =
  (): CostsPerServiceAndVehicleIds => {
    return {
      costsPerService: {
        [ServiceType.Fueling]: 0,
        [ServiceType.Breakdown]: 0,
        [ServiceType.Parking]: 0,
        [ServiceType.Washing]: 0,
        [ServiceType.Charging]: 0,
        [ServiceType.Other]: 0,
      },
      vehicleIds: new Set<string>(),
    };
  };

const groupCostsPerServiceAndVehiclesPerMonth = (
  transactions: Transaction[],
): CostsPerServiceAndVehicleIdsPerMonth => {
  return transactions.reduce(
    (draft: CostsPerServiceAndVehicleIdsPerMonth, transaction: Transaction) => {
      const date = moment(transaction.createdAt);
      const month: string = date.format(FORMAT_MONTH_PATTERN);

      if (draft[month] === undefined) {
        draft[month] = buildEmptyCostsPerServiceAndVehicleIds();
      }

      draft[month].costsPerService[transaction.serviceType] += parseFloat(
        transaction.totalAmountGross ?? '0',
      );

      draft[month].vehicleIds.add(transaction.vehicleId);

      return draft;
    },
    {},
  );
};

const sortAndFillMissingMonths = (months: string[]): string[] => {
  if (months.length === 0) {
    return months;
  }

  const sortedMonths: string[] = [...months].sort((a, b) => a.localeCompare(b));
  const firstMonth = moment(sortedMonths.at(0));
  const lastMonth = moment(sortedMonths.at(-1));

  const filledMonths: string[] = [];
  for (const month = firstMonth; month <= lastMonth; month.add(1, 'months')) {
    filledMonths.push(month.format(FORMAT_MONTH_PATTERN));
  }
  return filledMonths;
};

export const useChartData = () => {
  const { formatDate } = useCustomIntl();

  const buildChartData = (transactions: Transaction[]): ChartData => {
    const costsPerServiceAndVehicleIdsPerMonth: CostsPerServiceAndVehicleIdsPerMonth =
      groupCostsPerServiceAndVehiclesPerMonth(transactions);

    const months: string[] = Object.keys(costsPerServiceAndVehicleIdsPerMonth);

    const filledSortedMonths: string[] = sortAndFillMissingMonths(months);

    return filledSortedMonths.map((month: string) => {
      const costsPerServiceAndVehicleIds =
        costsPerServiceAndVehicleIdsPerMonth[month] ??
        buildEmptyCostsPerServiceAndVehicleIds();
      return {
        label: formatDate(moment(month).toISOString(), { ...yearMonthFormat }),
        costsPerService: costsPerServiceAndVehicleIds.costsPerService,
        numberOfVehicles: costsPerServiceAndVehicleIds.vehicleIds.size,
      };
    });
  };

  return {
    buildChartData,
  };
};
