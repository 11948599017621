import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { CustomFormattedMessage } from '../../i18n/i18n';
import { useAppDispatch } from '../../state/hooks';
import { userManualDialogChapters } from '../../state/dialogs/chapters';
import { appConfiguration } from '../../configuration/appConfiguration';
import { openUserManualDialog } from '../../state/dialogs/useUserManualDialog';
import { openSupportDialog } from '../../state/dialogs/useSupportDialog';

export const InfoPopover = () => {
  const dispatch = useAppDispatch();
  return (
    <ActionBarItem.List className="ServiceInfoPopoverContent">
      {appConfiguration.featureToggles.enableUserManual && (
        <ActionBarItem.ListItem
          data-testid="open-user-menu-dialog-btn"
          icon="rioglyph-question-sign"
          onClick={() =>
            dispatch(openUserManualDialog(userManualDialogChapters.welcome))
          }
        >
          <CustomFormattedMessage id="common.information.help" />
        </ActionBarItem.ListItem>
      )}
      <ActionBarItem.ListItem
        data-testid="open-support-dialog-btn"
        icon="rioglyph-envelope"
        onClick={() => dispatch(openSupportDialog())}
      >
        <CustomFormattedMessage id="common.information.support" />
      </ActionBarItem.ListItem>
    </ActionBarItem.List>
  );
};
