import classNames from 'classnames';
import { CustomFormattedMessage } from '../../../../../../i18n/i18n';
import { useAppDispatch, useAppSelector } from '../../../../../../state/hooks';
import {
  DialogMode,
  getDialogMode,
  setDialogMode,
} from '../../../../state/onboardingUiSlice';

export const ServiceTab = () => {
  const dispatch = useAppDispatch();
  const dialogMode = useAppSelector(getDialogMode);

  return (
    <li
      data-testid={'onboarding-dialog-tab-services'}
      className={classNames({ active: dialogMode === DialogMode.SERVICES })}
    >
      <div onClick={() => dispatch(setDialogMode(DialogMode.SERVICES))}>
        <CustomFormattedMessage id={'onboarding.dialog.services.tab'} />
      </div>
    </li>
  );
};
