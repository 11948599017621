import {
  CustomFormattedMessage,
  TranslationsKeys,
} from '../../../../i18n/i18n';

type ChapterBulletPointProps = {
  messageId: TranslationsKeys;
};

export const ChapterBulletPoint = ({ messageId }: ChapterBulletPointProps) => (
  <li>
    <CustomFormattedMessage
      id={messageId}
      values={{
        i: (parts: string) => <i>{parts}</i>,
      }}
    />
  </li>
);
