import {
  PaymentMethod,
  PaymentMethodState,
  ServiceActivation,
  useRemovePaymentMethodMutation,
} from '../../../../../../codegen/vehicles';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { ServiceCardDetails } from './ServiceCardDetails';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { filter } from 'lodash';
import { useAppDispatch } from '../../../../../../state/hooks';
import { useVehicleDialogFormContext } from '../../ServiceConfigurationForm';
import { useUpdatePaymentMethodsOfCachedVehicles } from '../../../../../../state/vehicles/useVehicles';
import { openRemoveConfirmationDialog } from '../../../../state/onboardingUiSlice';
import { ErrorMessagePanel } from '../../../../../../components/ErrorMessagePanel';

type ServiceCardPanelProps = {
  vehicleId: string;
  paymentMethod: PaymentMethod;
};

export const ServiceCardPanel = ({
  vehicleId,
  paymentMethod,
}: ServiceCardPanelProps) => {
  const dispatch = useAppDispatch();
  const {
    removePaymentMethod: removePaymentMethodFromForm,
    getServiceActivations,
  } = useVehicleDialogFormContext();
  const [callRemovePaymentMethodMutation] = useRemovePaymentMethodMutation();

  const { removePaymentMethodFromCachedVehicles } =
    useUpdatePaymentMethodsOfCachedVehicles();

  const handleRemoveCard = (paymentMethodId: string) => {
    const serviceActivations = getServiceActivations();
    const serviceActivationsWithPaymentMethod: ServiceActivation[] = [];

    map(serviceActivations, (serviceActivation: ServiceActivation) => {
      const a = filter(serviceActivation.paymentMethodAssignments, {
        paymentMethodId,
      });
      if (!isEmpty(a)) {
        serviceActivationsWithPaymentMethod.push(serviceActivation);
      }
    });

    if (!isEmpty(serviceActivationsWithPaymentMethod)) {
      dispatch(
        openRemoveConfirmationDialog({
          serviceActivations: serviceActivationsWithPaymentMethod,
          paymentMethodId,
        }),
      );
    } else {
      removePaymentMethod(paymentMethodId);
    }
  };

  const removePaymentMethod = async (paymentMethodId: string) => {
    const result = await callRemovePaymentMethodMutation({
      vehicleId,
      paymentMethodId,
    });

    if ('error' in result) {
      return;
    }

    removePaymentMethodFromForm(paymentMethodId);
    removePaymentMethodFromCachedVehicles(paymentMethodId);
  };

  return (
    <div
      className="width-100pct border margin-top-10 padding-bottom-10 padding-top-10"
      data-testid={'onboarding-dialog-body-service-card-panel'}
    >
      <div
        className="
        display-flex
        justify-content-between
        padding-right-20"
      >
        <div className="display-flex align-items-center padding-left-10">
          <Button
            data-testid={'onboarding-dialog-body-removeFuelCardButton'}
            bsStyle={Button.MUTED}
            iconOnly
            onClick={() => handleRemoveCard(paymentMethod.id)}
            disabled={
              paymentMethod.state === PaymentMethodState.OnboardingPending
            }
          >
            <span className="rioglyph rioglyph-trash" />
          </Button>
        </div>
        <div className="width-300">
          <ServiceCardDetails paymentMethod={paymentMethod} />
        </div>
      </div>
      {paymentMethod.state === PaymentMethodState.OnboardingFailure && (
        <div
          className={
            'full-width align-items-center padding-right-10 padding-left-10'
          }
        >
          <ErrorMessagePanel />
        </div>
      )}
    </div>
  );
};
