import { CustomFormattedMessage } from '../../i18n/i18n';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { ServiceConfigurationDialog } from './components/dialogs/serviceConfigurationDialog/ServiceConfigurationDialog';
import { VehicleTable } from './components/table/VehicleTable';
import { useVehicles } from '../../state/vehicles/useVehicles';
import { NoDataPlaceholder } from '../../components/NoDataPlaceholder';
import { useCheckDeeplinking } from '../../routes/useCheckDeeplinking';

export const OnboardingPage: React.FC = () => {
  const { isLoading, error } = useVehicles();
  useCheckDeeplinking();

  if (error) {
    return <NoDataPlaceholder />;
  }

  return (
    <div className="display-flex flex-column height-100pct">
      <h3>
        <CustomFormattedMessage id={'onboarding.body.title'} />
      </h3>
      <div className="text-size-18 margin-bottom-10">
        <CustomFormattedMessage id={'onboarding.body.subtitle'} />
      </div>
      <div className="height-100pct overflow-y-auto">
        {isLoading ? (
          <Spinner isDoubleSized />
        ) : (
          <>
            <ServiceConfigurationDialog />
            <VehicleTable />
          </>
        )}
      </div>
    </div>
  );
};
