import { ChapterHeadline } from '../chapterComponents/ChapterHeadline';
import { ChapterText } from '../chapterComponents/ChapterText';

export const WashingChapter: React.FC = () => {
  return (
    <div className={'row'}>
      <ChapterHeadline
        messageId={'userManual.washing.activationUse.headline'}
      />
      <ChapterText messageId={'userManual.washing.activationUse.text'} />
    </div>
  );
};
