import { DialogMode } from '../../../state/onboardingUiSlice';
import { VehicleHeadline } from '../../VehicleHeadline';
import { useSelectedVehicle } from '../../../../../state/vehicles/useVehicles';
import { ServiceTabContent } from './services/ServiceTabContent';
import { CardTabContent } from './cards/CardTabContent';
import { ServiceTab } from './services/ServiceTab';
import { CardTab } from './cards/CardTab';

type ServiceConfigurationDialogBodyProps = {
  dialogMode: DialogMode;
};

export const ServiceConfigurationDialogBody = ({
  dialogMode,
}: ServiceConfigurationDialogBodyProps) => {
  const selectedVehicle = useSelectedVehicle();

  if (!selectedVehicle) {
    return <></>;
  }

  return (
    <div>
      <div className="margin-top-5 margin-bottom-50">
        <VehicleHeadline vehicle={selectedVehicle} />
      </div>
      <ul className="nav nav-tabs user-select-none">
        <ServiceTab />
        <CardTab />
      </ul>
      {dialogMode === DialogMode.SERVICES ? (
        <ServiceTabContent />
      ) : (
        <CardTabContent />
      )}
    </div>
  );
};
